import api from '@/api'
import catchAsync from '@/helpers/catchAsync'

const initialState = () => ({
	jobs: [],
	jobsMeta: null,

	job: null,

	activePage: 1
})

export default {
	namespaced: true,

	state: initialState(),

	getters: {
		jobsCount: state => state.jobsMeta?.found_posts,
		currentPage: state => state.jobsMeta?.page,
		pageCount: state => state.jobsMeta?.max_page
	},

	mutations: {
		setJobs(state, jobs) {
			state.jobs = jobs
		},
		setJobsMeta(state, jobsMeta) {
			state.jobsMeta = jobsMeta
		},
		setJob(state, job) {
			state.job = job
		},
		setActivePage(state, activePage) {
			state.activePage = activePage
		}
	},

	actions: {
		getJobs: catchAsync(
			async ({ commit, dispatch }, page = 1) => {
				commit('setActivePage', page)

				const queryStr = await dispatch('createQueryStr')

				const response = await api.getJobs(queryStr)
				const jobs = response.data.data
				const jobsMeta = response.data.meta

				commit('setJobs', jobs)
				commit('setJobsMeta', jobsMeta)
			}
		),
		getJob: catchAsync(
			async ({ commit }, id) => {
				const response = await api.getJob(id)
				const job = response.data.data

				commit('setJob', job)
			}
		),
		saveJob: catchAsync(
			async ({ dispatch }, id) => {
				await api.saveJob(id)

				await dispatch('saveJobLocally', id)
			}
		),
		applyForJob: catchAsync(
			async (store, user) => {
				const formData = new FormData()

				if (user.resume)
					formData.append('cv_file', user.resume)
				else
					formData.append('filename', user.existingResume)

				formData.append('job_id', user.jobId)

				const nonceResponse = await api.getNonce()

				const nonce = nonceResponse.data

				console.log('nonce', nonce)

				if (nonce)
					formData.append('nonce', nonce)

				await api.applyForJob(formData)
			}
		),
		saveJobLocally({ state, commit }, id) {
			const updatedJobs = [...state.jobs]
			const updatedJobIdx = updatedJobs.findIndex(
				job => job.id === id
			)

			updatedJobs[updatedJobIdx].is_saved = true
			commit('setJobs', updatedJobs)
		},
		getJobLink({ state }, id) {
			const selectedJob = state.jobs.find(
				job => job.id === id
			)

			return selectedJob.permalink
		},
		clearJobs({ commit }) {
			commit('setJobs', [])
			commit('setJobsMeta', null)
		},
		createQueryStr({ rootState, state }) {
			let queryStr = `?search=${rootState.filters.searchStr || ''}`
			queryStr += `&page=${state.activePage}`

			Object
				.entries(rootState.filters.selectedFilters)
				.forEach(
					([category, values]) => {
						queryStr += `&${category}=`

						values.forEach(
							value => queryStr += `${value},`
						)
					}
				)

			return queryStr
		}
	}
}
