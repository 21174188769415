<template>
	<div v-if="!isLoading">
		<div class="container">
			<h1>{{ alert?.title }}</h1>
		</div>

		<JobsList />
	</div>
</template>

<script>
import {
	onMounted,
	onUnmounted,
	ref,
	computed
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import JobsList from '@/components/posted-jobs/JobsList.vue'

export default {
	components: { JobsList },

	setup() {
		const store = useStore()
		const route = useRoute()

		const alert = ref()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		onMounted(
			async () => {
				const { scrollPos } = await store.dispatch('applySessionInfo')

				if (!scrollPos) {
					alert.value = await store.dispatch(
						'alerts/getJobAlert',
						+route.params.id
					)

					await store.dispatch('filters/applyFilters', alert.value)
				}

				await store.dispatch('postedJobs/getJobs')
				await store.commit('setIsPageLoading', false)

				window.scrollTo(0, scrollPos || 0)
			}
		)

		onUnmounted(
			async () => {
				await Promise.all([
					store.dispatch('filters/clearFiltersAndSearch'),
					store.dispatch('postedJobs/clearJobs')
				])
			}
		)

		return { alert, isLoading }
	}
}
</script>

<style lang="scss" scoped>
h1 {
	font-size: 42px;
	text-align: center;
	margin: 40px 0;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@include media('<=tablet') {
		font-size: 25px;
		margin: 20px 0;
	}
}
</style>
