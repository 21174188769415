import { ref, watch } from 'vue'

function usePagination(items, itemsPerPage = 2) {
	const activeItems = ref([])
	const activePage = ref(0)
	const pageCount = ref()

	watch(
		items,
		() => {
			pageCount.value = Math.ceil(
				items.value.length / itemsPerPage
			)

			sliceActiveItems()
		},
		{ immediate: true }
	)

	function onPageChange(page) {
		activePage.value = page - 1

		sliceActiveItems()
	}

	function sliceActiveItems() {
		const sliceFrom = (activePage.value * itemsPerPage)
		const sliceTo = sliceFrom + itemsPerPage

		activeItems.value = items.value.slice(sliceFrom, sliceTo)
	}

	return [
		activeItems,
		pageCount,
		onPageChange
	]
}

export default usePagination
