import jwtDecode from 'jwt-decode'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import PostedJobs from '@/views/PostedJobs.vue'
import Alert from '@/views/Alert.vue'
import AlertUpdate from '@/views/AlertUpdate.vue'
import AlertCreate from '@/views/AlertCreate.vue'
import Dashboard from '@/views/Dashboard.vue'
import DashboardUser from '@/views/DashboardUser.vue'
import DashboardAlerts from '@/views/DashboardAlerts.vue'
import ApplyForJob from '@/views/ApplyForJob.vue'
import Register from '@/views/Register.vue'
import RegisterApply from '@/views/RegisterApply.vue'
import SignIn from '@/views/SignIn.vue'
import SignInApply from '@/views/SignInApply.vue'
import ResetPassword from '@/views/ResetPassword.vue'

const defaultScrollPos = () => ({ top: 0, left: 0 })

const routes = [
	{
		path: '/reset-password/',
		name: 'ResetPassword',
		component: ResetPassword
	},
	{
		path: '/jobs/',
		name: 'PostedJobs',
		meta: { scrollPos: defaultScrollPos() },
		component: PostedJobs
	},

	// alerts
	{
		path: '/alert/:id/',
		name: 'Alert',
		meta: { auth: true, scrollPos: defaultScrollPos() },
		component: Alert
	},
	{
		path: '/update-alert/:id/',
		name: 'AlertUpdate',
		meta: { auth: true },
		component: AlertUpdate
	},
	{
		path: '/create-alert/',
		name: 'AlertCreate',
		meta: { auth: true },
		component: AlertCreate
	},

	// dashboard
	{
		path: '/dashboard/',
		name: 'Dashboard',
		meta: { auth: true, title: 'Dashboard - Equiliem' },
		component: Dashboard
	},
	{
		path: '/dashboard/user/',
		name: 'DashboardUser',
		meta: { auth: true, title: 'User profile - Equiliem' },
		component: DashboardUser
	},
	{
		path: '/dashboard/alerts/',
		name: 'DashboardAlerts',
		meta: { auth: true, title: 'Job alerts - Equiliem' },
		component: DashboardAlerts
	},

	// forms
	{
		path: '/apply-for-job/:id/',
		name: 'ApplyForJob',
		component: ApplyForJob,
		meta: { title: 'Apply for job - Equiliem' }
	},
	{
		path: '/register/:id?/',
		name: 'Register',
		component: Register,
		meta: { title: 'User registration - Equiliem' }
	},
	{
		path: '/register-apply/:id/:guest?/',
		name: 'RegisterApply',
		component: RegisterApply,
		meta: {
			title: 'Register to apply - Equiliem',
			secondTitle: 'Apply without an account - Equiliem'
		}
	},
	{
		path: '/sign-in/:id?/',
		name: 'SignIn',
		component: SignIn
	},
	{
		path: '/sign-in-apply/:id/',
		name: 'SignInApply',
		component: SignInApply,
		meta: { title: 'Sign in and apply - Equiliem' }
	}
]

const router = createRouter({
	routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPos) {
		return savedPos || to.meta?.scrollPos || defaultScrollPos()
	}
})

router.beforeEach((to, from, next) => {
	store.commit('setIsPageLoading', true)

	const token = localStorage.getItem('th-token')
	const isTokenExpired = token && jwtDecode(token).exp < Date.now() / 1000

	const { params } = to
	const { title, secondTitle, auth } = to.meta
	const { scrollPos } = from.meta

	if (title) document.title = title

	// dynamic title for register-apply page
	if (params.guest) document.title = secondTitle

	if (auth && !token) return next({ name: 'Register', params })

	if (auth && token && isTokenExpired) return next({ name: 'SignIn', params })

	if (scrollPos) scrollPos.top = window.scrollY

	next()
})

export default router
