import { createStore } from 'vuex'

import postedJobs from './postedJobs'
import user from './user'
import userJobs from './userJobs'
import alerts from './alerts'
import filters from './filters'

export default createStore({
	state: {
		error: null,
		isPageLoading: false
	},

	mutations: {
		setError(state, error) {
			state.error = error
		},
		setIsPageLoading(state, isPageLoading) {
			state.isPageLoading = isPageLoading
		}
	},

	actions: {
		saveSessionInfo({ rootState }) {
			const { searchStr, selectedFilters } = rootState.filters
			const { activePage } = rootState.postedJobs
			const { scrollTop } = document.documentElement

			const thJobsState = {
				scrollPos: scrollTop,
				search: searchStr,
				filters: selectedFilters,
				page: activePage
			}

			sessionStorage.setItem(
				'th-jobs-state',
				JSON.stringify(thJobsState)
			)
		},
		applySessionInfo({ commit }) {
			const thJobsState = sessionStorage.getItem('th-jobs-state')
			if (!thJobsState) return {}

			const parsedThJobsState = JSON.parse(thJobsState)
			const { page, search, filters } = parsedThJobsState

			if (page) commit('postedJobs/setActivePage', +page)
			if (search) commit('filters/setSearchStr', search)
			if (filters) commit('filters/setSelectedFilters', filters)

			sessionStorage.removeItem('th-jobs-state')

			return parsedThJobsState
		}
	},

	modules: {
		postedJobs,
		user,
		userJobs,
		alerts,
		filters
	}
})
