import request from './requestHandler'

const api = {
	// user
	getUser: () => request.get('/user?add_consents=1'),
	getResumes: () => request.get('/user/resume'),
	uploadResume: resume => request.post('/user/resume', resume),
	deleteResume: name => request.delete(`/user/resume?filename=${name}`),
	updateUser: user => request.post('/user', user),
	requestRtbf: () => request.post('/user/rtbf'),
	resetPassword: email => request.post('/reset-password', email),
	setPassword: userData => request.post('/set-password', userData),

	// user auth
	signUp: user => request.post('/register', user),
	signIn: user => request.post('/token', user),
	logOut: () => request.post('/logout'),

	// jobs
	getFilters: () => request.get('/jobs/filter'),
	getJobs: queryStr => request.get(`/jobs${queryStr}`),
	getJob: id => request.get(`/jobs/${id}`),

	// user jobs
	getUserJobs: () => request.get('/saved-jobs?per_page=300'),
	getSuggestedJobs: () => request.get('/user/suggested-jobs'),
	deleteUserJob: id => request.delete(`/saved-jobs/${id}`),

	// alerts
	getJobAlerts: () => request.get('/job-alerts?per_page=300'),
	getJobAlert: id => request.get(`/job-alerts/${id}`),
	addJobAlert: alert => request.post('/job-alerts', alert),
	updateJobAlert: ({ id, alert }) => request.post(`/job-alerts/${id}`, alert),
	deleteJobAlert: id => request.delete(`/job-alerts/${id}`),

	// save, apply for the job
	saveJob: id => request.post('/saved-jobs', { id }),
	applyForJob: data => request.post('/apply-job', data),
	getNonce: () => request.get('/nonce'),
	signInApply: data => request.post('/apply-job/login', data),
	registerApply: data => request.post('/apply-job/register', data),
	registerApplyGuest: data => request.post('/apply-job/guest', data)
}

export default api
