<template>
	<div class="jobs" id="jobs">
		<div class="container">
			<div class="jobs__wrapper">
				<span class="jobs__count">
					Found {{ jobsCount }} jobs
				</span>

				<ul class="jobs__list">
					<JobsListItem
						v-for="job of jobs"
						:job="job"
						:key="job.id"
					/>
				</ul>

				<div class="jobs__footer">
					<!-- <SubscriptionForm /> -->

					<Pagination />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import JobsListItem from './JobsListItem.vue'
import Pagination from './Pagination.vue'

export default {
	components: { JobsListItem, Pagination },

	setup() {
		const store = useStore()

		const jobs = computed(
			() => store.state.postedJobs.jobs
		)

		const jobsCount = computed(
			() => store.getters['postedJobs/jobsCount']
		)

		return { jobs, jobsCount }
	}
}
</script>

<style lang="scss">
.jobs {
	&__wrapper {
		padding: 50px 0 115px;
		background-color: $white;

		@include media('<=ipad') {
			padding: 35px 0 60px;
		}
	}

	&__count {
		display: block;
		margin-bottom: 50px;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.3475;
		color: $orange;

		@include media('<=tablet') {
			margin-bottom: 30px;
		}
	}

	&__list {
		margin-bottom: 40px;
		padding: 0;
		list-style: none;

		@include media('<=ipad') {
			margin-bottom: 35px;
		}

		[data-vue-component-name="JobsListItem"] {
			border-bottom: 1px solid $black;

			&:first-of-type {
				.jobs-card {
					padding-top: 0;
				}
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: right;

		@include media('<=ipad')  {
			flex-wrap: wrap;
		}

		[data-vue-component-name="SubscriptionForm"] {
			@include media('<=ipad') {
				flex-shrink: 0;
				margin-bottom: 35px;
				margin-right: 20px;
			}
		}

		.pagination {
			@include media('<=ipad') {
				margin-bottom: 0;
				margin-right: 0;
			}

			@include media('<=tablet') {
				margin-left: auto;
			}
		}
	}
}
</style>
