<template>
	<ul
		v-if="pageCount > 1"
		data-vue-component-name="Pagination"
	>
		<li
			v-for="(_, item) of Array(pageCount).fill()"
			:key="item"
			:class="{ activeItem: item === activeItem }"
			@click="onClickHandler(item)"
		/>
	</ul>
</template>

<script>
import { ref } from 'vue'

export default {
	emits: ['onPageChange'],
	props: { pageCount: Number },

	setup(props, ctx) {
		const activeItem = ref(0)

		function onClickHandler(item) {
			ctx.emit('onPageChange', item + 1)
			activeItem.value = item
		}

		return { onClickHandler, activeItem }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Pagination"] {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	list-style: none;
	margin-top: auto;
	padding-top: 20px;

	li {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 20px;
		background: $dark-grey;
		cursor: pointer;

		&:last-of-type {
			margin-right: 0;
		}

		&.activeItem {
			background: $black;
		}
	}
}
</style>
