<template>
	<div v-if="loading" class="loader">
		<div class=""></div>
		<div class=""></div>
		<div class=""></div>
		<div class=""></div>
	</div>
</template>

<script>
export default {
	props: { loading: Boolean }
}
</script>

<style lang="scss" scoped>
	.loader {
		display: inline-block;
		position: relative;
		width: 40px;
		height: 40px;
		margin: 0 auto 20px;
	}

	.loader div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 30px;
		height: 30px;
		margin: 5px;
		border: 2px solid $black;
		border-radius: 50%;
		animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $black transparent transparent transparent;
	}

	.loader div:nth-child(1) {
		animation-delay: -0.45s;
	}

	.loader div:nth-child(2) {
		animation-delay: -0.3s;
	}

	.loader div:nth-child(3) {
		animation-delay: -0.15s;
	}

	@keyframes loader {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
