import { ref } from 'vue'

function useLoader() {
	const loading = ref(false)

	async function proceedLoading(cb) {
		loading.value = true

		await cb()

		loading.value = false
	}

	return [loading, proceedLoading]
}

export default useLoader
