<template>
	<div data-vue-component-name="FiltersGroup" class="group" :class="title">
		<h4 v-if="title">
			{{ title }}
		</h4>

		<div
			class="filter"
			:class="{ nested: filter.cities }"
			v-for="filter of group"
			:key="filter.value"
		>
			<!-- <span
				v-if="filter.cities?.length > 0"
				class="show-more"
				:data-name="filter.name"
				@click.stop="toggleAccordion(filter.name)"
				:class="{ active: openAccordion === filter.name }"
			/> -->

			<FiltersCheckbox
				:checked="isSelected(filter)"
				:filter="filter"
				@selectFilter="selectFilter"
			/>

			<FiltersAccordion
				v-if="
					filter.cities?.length > 0 &&
					openAccordion === filter.name
				"
				@toggleAccordion="toggleAccordion"
			>
				<FiltersCheckbox
					v-for="subfilter of filter.cities"
					:checked="isSelected(subfilter)"
					:filter="subfilter"
					:key="subfilter.value"
					:sub="true"
					@selectFilter="selectFilter"
				/>
			</FiltersAccordion>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import { useFilters } from '@/hooks'

import FiltersCheckbox from './FiltersCheckbox.vue'
import FiltersAccordion from './FiltersAccordion.vue'

export default {
	components: { FiltersCheckbox, FiltersAccordion },

	emits: ['updateStoreFilters'],
	props: {
		title: String,
		groupName: String,
		group: Array,
		initialState: Array
	},

	setup(props) {
		const {
			isSelected,
			selectFilter,
			selectedFilters
		} = useFilters(props.groupName)

		const openAccordion = ref(null)

		function toggleAccordion(name) {
			if (name === openAccordion.value)
				return openAccordion.value = null

			openAccordion.value = name
		}

		return {
			openAccordion,
			toggleAccordion,
			isSelected,
			selectFilter,
			selectedFilters
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="FiltersGroup"] {
	margin-bottom: 40px;

	h4 {
		width: 100%;
		margin-bottom: 12px;
		font-size: 16px;
		font-weight: bold;
		color: $orange;
	}

	.filter {
		position: relative;
		width: 33.3%;
		margin-right: auto;
		margin-bottom: 10px;
		break-inside: avoid;

		@include media('<tablet') {
			width: 100%;
		}

		&:nth-last-child(2) {
			margin-right: 0;
		}

		& * {
			break-inside: avoid;
		}

		&.nested {
			padding-left: 25px;
		}

		.show-more {
			cursor: pointer;
			position: absolute;
			top: 7px;
			left: 0;
			width:14px;
			height:14px;
			background:
				linear-gradient($orange 0 0),
				linear-gradient($orange 0 0),
				$light-grey;
			background-position: center;
			background-size: 100% 2px, 2px 100%;
			background-repeat: no-repeat;

			&.active {
				background: linear-gradient($orange 0 0), $light-grey;
				background-position: center;
				background-size: 100% 2px, 2px 100%;
				background-repeat: no-repeat;
			}
		}

		&__accordion {
			margin: 15px 0 10px;
			position: absolute;
			top: 20px;
			max-height: 140px;
			background: $white;
			left: 0;
			right: 15px;
			overflow: auto;
			z-index: 1;
			border-radius: 10px;
			box-shadow: 0px 0px 5px 0px $dark-grey;
			padding: 10px 15px 0 25px;

			[data-vue-component-name="FiltersCheckbox"] {
				margin-bottom: 10px;

				:deep(.jobs-count) {
					margin-right: 0;
				}

				:deep(.checkbox) {
					min-width: 80px;
				}
			}
		}
	}
}
</style>
