<template>
	<Wrapper v-if="!isLoading">
		<SavedJobs :itemsPerPage="3" />
		<JobAlerts :itemsPerPage="20" />
		<SuggestedJobs :itemsPerPage="10" />
	</Wrapper>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

import Wrapper from '@/components/dashboard/Wrapper.vue'
import SavedJobs from '@/components/dashboard/SavedJobs.vue'
import JobAlerts from '@/components/dashboard/JobAlerts.vue'
import SuggestedJobs from '@/components/dashboard/SuggestedJobs.vue'

export default {
	components: {
		Wrapper,
		SavedJobs,
		JobAlerts,
		SuggestedJobs
	},

	setup() {
		const store = useStore()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		onMounted(
			async () => {
				await Promise.all([
					store.dispatch('alerts/getJobAlerts'),
					store.dispatch('userJobs/getJobs'),
					store.dispatch('userJobs/getSuggestedJobs')
				])

				store.commit('setIsPageLoading', false)
			}
		)

		return { isLoading }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Wrapper"] {
	@include media('>=ipad') {
		grid-template-rows:  1fr 1fr;
	}
}

[data-vue-component-name="SavedJobs"] {
	grid-column: 1/2;
	grid-row: 1/3;
	display: flex;
	flex-direction: column;
	flex: 1;

	:deep([data-vue-component-name="Link"].more-jobs) {
		margin-top: auto;
		margin-bottom: 20px;
		text-align: left;
	}

	:deep([data-vue-component-name="GreyBlank"]) {
		@include media('>=tablet') {
			margin-bottom: 0;
		}
	}
}

[data-vue-component-name="JobAlerts"] {
	grid-column: 2/4;
	grid-row: 1/5;
}

[data-vue-component-name="SuggestedJobs"] {
	grid-column: 1/2;
	grid-row: 3/5;

	@include media('<tablet') {
		order: 2;
	}
}
</style>
