<template>
	<div data-vue-component-name="SuggestedJobs" class="suggestedJobs-dashboard">
		<GreyBlank
			title="Suggested jobs"
			:count="jobsCount"
		>
			<div class="suggested-container">
				<div
					class="suggested-item"
					v-for="job of jobs"
					:key="job.id"
				>
					<Link
						@click="onClickHandler(job.permalink)"
						bold
					>
						{{ job.title }}
					</Link>

					<span class="sj-city">{{ job.city }}</span>
					<span class="sj-salary" v-if="job.salary">{{ job.salary }}</span>
				</div>

				<p
					v-if="!jobsCount"
					class="suggested-create"
				>
					Create a job alert or save a job to see suggested jobs
				</p>
			</div>

			<Pagination
				:pageCount="pageCount"
				@onPageChange="onPageChange"
			/>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { usePagination } from '@/hooks'

import Link from '@/components/ui/Link.vue'
import Pagination from './Pagination.vue'
import GreyBlank from './GreyBlank.vue'

export default {
	components: {
		Link,
		Pagination,
		GreyBlank
	},

	props: { itemsPerPage: Number },

	setup(props) {
		const suggestedJobs = computed(
			() => store.state.userJobs.suggestedJobs
		)
		const jobsCount = computed(
			() => suggestedJobs.value.length
		)

		const store = useStore()
		const [jobs, pageCount, onPageChange] = usePagination(
			suggestedJobs,
			props.itemsPerPage
		)

		function onClickHandler(link) {
			window.location.href = link
		}

		return {
			jobs,
			pageCount,
			onPageChange,
			jobsCount,
			onClickHandler
		}
	}
}
</script>

<style lang="scss" scoped>
.suggested-container {
	.suggested-item {
		font-size: 12px;
		margin-bottom: 20px;

		&:last-of-type {
			margin: 0;
		}

		[data-vue-component-name="Link"] {
			margin-bottom: 5px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		span {
			display: block;
			margin-bottom: 12px;
		}

		p {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.suggested-create {
		font-size: 14px;
		text-align: center;
	}
}
</style>
