<template>
	<div data-vue-component-name="RegisterLink">
		<span>Don’t have an account?</span>
		<Link
			:to="{
				name: to || 'Register',
				params: $route.params
			}"
			orange
		>
			Register now
		</Link>
	</div>
</template>

<script>
import Link from '@/components/ui/Link.vue'

export default {
	components: { Link },
	props: { to: String }
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="RegisterLink"] {
	display: flex;

	span {
		margin-right: 10px;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.5;
	}
}
</style>
