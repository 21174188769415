<template>
	<input
		data-vue-component-name="Checkbox"
		type="checkbox"
		:id="id"
		:checked="checked"
		:class="{
			check: type === 'check',
			square: type === 'square'
		}"
	/>
</template>

<script>
export default {
	props: {
		id: [String, Number],
		checked: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'check'
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Checkbox"] {
	-webkit-appearance: none;
	appearance: none;
	background-color: $light-grey;
	min-width: 14px;
	min-height: 14px;
	border: 2px solid $black;
	cursor: pointer;
	position: relative;
	margin-right: 10px;

	&.check:before {
		content: "";
		width: 8px;
		height: 8px;
		position: absolute;
		top: 1px;
		left: 1px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}

	&.square:before {
		content: "";
		width: 6px;
		height: 6px;
		background: $black;
		position: absolute;
		top: 2px;
		left: 2px;
	}

	&:checked:before {
		background-image: url('~@/assets/images/check.svg');
	}
}
</style>
