<template>
	<div data-vue-component-name="ResetPasswordStepTwo">
		<p>
			We've sent a code to your email address.
			Please, check your inbox.
			Make sure you checked the Spam folder.
			<br />
			<br />
			After a successful change you will be redirected
			to the Sign In page, where you can log in with the new password.
		</p>

		<form @submit.prevent="onSubmit">
			<Input
				label="Code"
				type="text"
				v-model="code.value.value"
				:error="code.errorMessage.value"
			/>

			<Input
				label="New password"
				type="password"
				v-model="password.value.value"
				:error="password.errorMessage.value"
			/>

			<Input
				label="Password confirmation"
				type="password"
				v-model="passwordConfirm.value.value"
				:error="passwordConfirm.errorMessage.value"
			/>

			<ButtonLoader :loading="loading">
				Submit
			</ButtonLoader>
		</form>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useForm, useField } from 'vee-validate'
import { useLoader } from '@/hooks'

import * as v from '@/helpers/validators'
import API_ERROR_CODES from '@/constants/apiErrorCodes'

import Input from '@/components/ui/Input.vue'
import ButtonLoader from '@/components/ui/ButtonLoader.vue'

export default {
	components: { Input, ButtonLoader },

	props: { email: String },

	setup(props) {
		const store = useStore()
		const router = useRouter()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit } = useForm()
		const code = useField('code', v.defaultField)
		const password = useField('password', v.password)
		const passwordConfirm = useField(
			'passwordConfirm', v.passwordConfirm(password.value)
		)

		const error = computed(() => store.state.error)
		const isLoggedIn = computed(() => store.state.user.isLoggedIn)

		const onSubmit = handleSubmit(
			async (values, actions) => {
				const updatedData = { ...values, email: props.email }

				await proceedLoading(
					() => store.dispatch('user/setPassword', updatedData)
				)

				if (error.value) {
					const { code } = error.value.response.data
					const handledCode = API_ERROR_CODES[code]

					return handledCode && actions.setFieldError(
						handledCode.field,
						handledCode.message
					)
				}

				if (isLoggedIn.value) await store.dispatch('user/logOut')

				router.push({ name: 'SignIn' })
			}
		)

		return {
			code,
			onSubmit,
			password,
			passwordConfirm,
			loading
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='ResetPasswordStepTwo'] {
	margin-bottom: 30px;

	p {
		margin-bottom: 30px;
	}

	[data-vue-component-name='Input'] {
		margin-bottom: 30px;
	}

	.email {
		position: relative;

		[data-vue-component-name='Link'] {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
}
</style>
