<template>
	<Wrapper v-if="!isLoading" bordered>
		<div class="container applyForJobContainer" :class="title">
			<ApplyForJob class="left-slot applyForJob" />
		</div>
	</Wrapper>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Wrapper from '@/components/entry/Wrapper.vue'
import ApplyForJob from '@/components/entry/form/ApplyForJob.vue'

export default {
	components: {
		Wrapper,
		ApplyForJob
	},

	setup() {
		const store = useStore()
		const route = useRoute()

		const isLoading = computed(() => store.state.isPageLoading)

		onMounted(async () => {
			await store.dispatch('postedJobs/getJob', +route.params.id)

			store.commit('setIsPageLoading', false)
		})

		return { isLoading }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='Wrapper'] {
	padding: 40px 40px 60px;

	@include media('<tablet') {
		flex-direction: row;
		align-items: stretch;
	}
}
</style>
