<template>
	<div class="userSignOut" v-if="userEmail">
		<div data-vue-component-name="UserSignOut">
			<h2>{{ userName }}</h2>
			<span class="email">{{ userEmail }}</span>

			<span class="sign-out" @click="signOut">
				Not you? <span>Sign out</span>
			</span>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()

		const userName = computed(
			() => store.getters['user/userFullName']
		)

		const userEmail = computed(
			() => store.getters['user/userEmail']
		)

		async function signOut() {
			await store.dispatch('user/logOut')
		}

		return {
			userName,
			userEmail,
			signOut
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="UserSignOut"] {
	margin-bottom: 105px;

	@include media('<=ipad') {
		margin-bottom: 70px;
	}

	h2 {
		margin: 0 0 2px;
		font-size: 26px;
		font-weight: bold;
		line-height: 1.1538;
		color: $black;
	}

	.email {
		display: block;
		margin-bottom: 15px;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.5;
	}

	.sign-out {
		font-size: 12px;
		text-decoration: underline;
		cursor: pointer;
		font-weight: 300;
	}
}
</style>
