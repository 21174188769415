<template>
	<div data-vue-component-name="ResetPassword" class="registerUser">
		<div class="registerUserInnerContainer">
			<h1 class="title">
				Reset your password
			</h1>

			<ResetPasswordStepOne
				v-if="step === 0"
				@nextStep="nextStep"
			/>

			<ResetPasswordStepTwo
				v-if="step === 1"
				:email="email"
			/>

			<RegisterLink />
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'

import ResetPasswordStepOne from './ResetPasswordStepOne.vue'
import ResetPasswordStepTwo from './ResetPasswordStepTwo.vue'
import RegisterLink from '../RegisterLink.vue'

export default {
	components: { ResetPasswordStepOne, ResetPasswordStepTwo, RegisterLink },

	setup() {
		const step = ref(0)
		const email = ref('')

		function nextStep(_email) {
			step.value = 1
			email.value = _email
		}

		return { step, email, nextStep }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="ResetPassword"] {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 70px 36px 40px 40px;

	.title {
		margin: 0 0 26px;
		font-size: 42px;
		font-weight: normal;
		line-height: 1.0714;
		color: $black;

		@include media('<tablet') {
			margin: 0 0 30px;
			font-size: 26px;
		}
	}

	form {
		margin-bottom: 30px;

		[data-vue-component-name="Input"],
		[data-vue-component-name="InputFile"] {
			margin-bottom: 30px;
		}

		.email {
			position: relative;

			[data-vue-component-name="Link"] {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
			}
		}
	}
}
</style>
