import api from '@/api'
import catchAsync from '@/helpers/catchAsync'

const initialState = () => ({
	searchStr: '',
	filters: [],

	selectedFilters: {
		states: [],
		'job-types': [],
		industries: []
	}
})

export default {
	namespaced: true,

	state: initialState(),

	getters: {},

	mutations: {
		setFilters(state, filters) {
			state.filters = filters
		},
		setSelectedFilters(state, filters) {
			state.selectedFilters = filters
		},
		setSearchStr(state, searchStr) {
			state.searchStr = searchStr
		}
	},

	actions: {
		getFilters: catchAsync(
			async ({ commit }) => {
				const response = await api.getFilters()
				const filters = response.data.data.filter

				commit('setFilters', filters)
			}
		),
		applyFilters({ commit }, alert) {
			const {
				search,
				title,
				id,
				...filters
			} = alert

			commit('setSearchStr', search)
			commit('setSelectedFilters', filters)
		},
		clearFiltersAndSearch({ dispatch, commit }) {
			dispatch('clearFilters')
			commit('setSearchStr', '')
		},
		clearFilters({ commit }) {
			commit(
				'setSelectedFilters',
				initialState().selectedFilters
			)
		}
	}
}
