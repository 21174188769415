<template>
	<DefaultLayout>
		<router-view />
	</DefaultLayout>
</template>

<script>
import jwtDecode from 'jwt-decode'
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'

export default {
	components: { DefaultLayout },

	setup() {
		const store = useStore()

		onMounted(
			async () => {
				await store.dispatch('filters/getFilters')

				const token = localStorage.getItem('th-token')
				const user = localStorage.getItem('th-user')
				if (!token || !user) return

				const { exp } = jwtDecode(token)
				if (exp < Date.now() / 1000) return

				store.commit('user/setUser', JSON.parse(user))
				store.commit('user/setIsLoggedIn', true)
			}
		)
	}
}
</script>
