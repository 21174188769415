const API_ERROR_CODES = {
	// TODO change error messages and add new types
	password_weak_error: {
		field: 'password',
		message: 'Weak password'
	},
	email_not_found: {
		field: 'email',
		message: 'There is no account for this email'
	},
	restore_code_error: {
		field: 'code',
		message: 'Your reset code is expired'
	},
	restore_no_code_error: {
		field: 'code',
		message: 'You must get reset code before try to reset password'
	},
	restore_code_invalid_error: {
		field: 'code',
		message: 'The code is not valid. Please, try again'
	},
	email_conflict_error: {
		field: 'email',
		message: 'You already have an account'
	},
	register_email_error: {
		field: 'email',
		message: 'The email address is already associated with another user'
	},
	register_password_error: {
		field: 'password',
		message: 'Weak password'
	},
	reset_error: {
		field: 'email',
		message: 'No such email'
	},
	invalid_creds: {
		field: 'password',
		message: 'Invalid login or password'
	}
}

export default API_ERROR_CODES
