function catchAsync(fn) {
	return async (...args) => {
		const { commit } = args[0]

		commit('setError', null, { root: true })

		const result = await fn(...args).catch(
			err => commit('setError', err, { root: true })
		)

		if (result) return result
	}
}

export default catchAsync
