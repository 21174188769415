// function formattedSalary(salary) {
// 	if (salary.includes('-')) {
// 		const salaryInfo = salary.split(' ')
// 		salaryInfo[0] = +salaryInfo[0]
// 		salaryInfo[2] = +salaryInfo[2]
// 		salaryInfo[0] = salaryInfo[0].toLocaleString('en-US', {
// 			maximumFractionDigits: 2
// 		})
// 		salaryInfo[2] = salaryInfo[2].toLocaleString('en-US', {
// 			maximumFractionDigits: 2
// 		})
// 		return salaryInfo.join(' ')
// 	}
// 	if (!salary.includes('-')) {
// 		const salaryInfo = salary.split(' ')
// 		salaryInfo[0] = +salaryInfo[0]
// 		salaryInfo[0] = salaryInfo[0].toLocaleString('en-US', {
// 			maximumFractionDigits: 2
// 		})
// 		salaryInfo.unshift(`${salaryInfo[0]} -`)
// 		return salaryInfo.join(' ')
// 	}
// }

// export default formattedSalary

function formattedSalary(salary) {
	const CS = '$'
	let perUnitText = ''

	// Extracting the unit from the input string
	const perIndex = salary.indexOf('per')
	if (perIndex !== -1) perUnitText = salary.substring(perIndex).trim()

	if (salary.includes('-')) {
		const salaryInfo = salary.split(' ')
		salaryInfo[0] = parseFloat(salaryInfo[0]) // Parse as float
		salaryInfo[2] = parseFloat(salaryInfo[2]) // Parse as float
		salaryInfo[0] = salaryInfo[0].toLocaleString('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		})
		salaryInfo[2] = salaryInfo[2].toLocaleString('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		})
		return `${CS}${salaryInfo[0]} - ${CS}${salaryInfo[2]} ${perUnitText}`
	}
	const salaryInfo = salary.split(' ')
	const formattedSalary = parseFloat(salaryInfo[0]).toLocaleString('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})
	return `${CS}${formattedSalary} ${perUnitText}` // Add currency symbol and unit
}

export default formattedSalary
