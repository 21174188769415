<template>
	<label data-vue-component-name="InputFile" class="InputFileLabel">
		<span v-if="label" class="label">
			{{ label }}
		</span>

		<div class="custom-input" :class="{ error }">
			<div>{{ modelValue?.name || 'Drag and drop or upload here' }}</div>
			<!-- <input id="input" type="file" @change="onFileChange" ref="input" /> -->
			<input
				id="input"
				type="file"
				@change="onFileChange"
				ref="input"
				accept=".pdf,.txt,.docx,.rtf,.doc"
			/>
		</div>

		<span v-if="error" class="error">
			{{ error }}
		</span>
	</label>
</template>

<script>
import { ref } from 'vue'

export default {
	emits: ['update:modelValue'],
	props: { label: String, modelValue: File, error: String },

	setup(props, ctx) {
		const input = ref(null)

		function outerClick() {
			input.value.click()
		}

		function onFileChange(e) {
			const allowedTypes = ['pdf', 'txt', 'docx', 'doc', 'rtf']
			const resume = e.target.files[0]

			// if (!resume) return

			const extension = resume.name.split('.').pop().toLowerCase()

			if (!allowedTypes.includes(extension)) {
				ctx.emit('update:modelValue', null)
				ctx.emit(
					'update:error',
					'Please select a PDF, DOC, DOCX, RTF, or TXT file.'
				)
				return
			}

			ctx.emit('update:error', null)

			ctx.emit('update:modelValue', resume)
		}

		ctx.expose({ outerClick })

		return { onFileChange, input }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='InputFile'] {
	position: relative;
	display: block;

	span {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.5;

		&.label {
			display: block;
			margin-bottom: 15px;
			color: $black;
		}

		&.error {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			color: red;
		}
	}

	.custom-input {
		width: 100%;
		padding: 8px 19px;
		color: $black;
		background-color: $white;
		border-radius: 100px;
		border: 1px solid $white;
		font-size: 12px;
		position: relative;
		cursor: pointer;
		margin-bottom: 20px;
		display: inline-block;

		&.error {
			border: 1px solid red;
		}

		&:after {
			content: 'Browse';
			position: absolute;
			right: 19px;
			top: 8px;
			font-weight: 300;
		}

		input {
			display: none;
		}
	}
}
</style>
