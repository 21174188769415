import axios from 'axios'

let baseURL = process.env.VUE_APP_DEV_API_URL
if (process.env.NODE_ENV === 'production') baseURL = window.th_vars.api_base

const config = {
	baseURL,
	auth: {
		username: process.env.VUE_APP_DEV_AUTH_USERNAME,
		password: process.env.VUE_APP_DEV_AUTH_PASSWORD
	}
}

const http = axios.create(config)
http.interceptors.request.use(req => {
	const token = localStorage.getItem('th-token')
	req.headers['Board-Auth'] = `Bearer ${token}`

	return req
})

export default http
