import api from '@/api'
import catchAsync from '@/helpers/catchAsync'

const initialState = () => ({
	jobs: [],
	jobsMeta: {},

	suggestedJobs: []
})

export default {
	namespaced: true,

	state: initialState(),

	getters: {
		jobsCount: state => state.jobsMeta.found_posts,
		suggestedJobsCount: state => state.suggestedJobs.length
	},

	mutations: {
		setJobs(state, jobs) {
			state.jobs = jobs
		},
		setJobsMeta(state, jobsMeta) {
			state.jobsMeta = jobsMeta
		},
		setSuggestedJobs(state, suggestedJobs) {
			state.suggestedJobs = suggestedJobs
		}
	},

	actions: {
		getJobs: catchAsync(
			async ({ commit }, count) => {
				const response = await api.getUserJobs(count)
				const jobs = response.data.data
				const jobsMeta = response.data.meta

				commit('setJobs', jobs)
				commit('setJobsMeta', jobsMeta)
			}
		),
		getSuggestedJobs: catchAsync(
			async ({ commit }, count) => {
				const response = await api.getSuggestedJobs(count)
				const jobs = response.data.data

				commit('setSuggestedJobs', jobs)
			}
		),
		deleteJob: catchAsync(
			async ({ state, commit }, id) => {
				await api.deleteUserJob(id)

				const updatedJobs = state.jobs.filter(
					job => job.id !== id
				)

				const updatedJobsMeta = { ...state.jobsMeta }
				updatedJobsMeta.found_posts -= 1

				commit('setJobs', updatedJobs)
				commit('setJobsMeta', updatedJobsMeta)
			}
		)
	}
}
