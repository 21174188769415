<template>
	<div data-vue-component-name="ChooseResume">
		<form @submit.prevent="onSubmit">
			<Select
				v-if="resumesCount > 0"
				ref="selectRef"
				label=""
				v-model="existingResume"
				:options="isSafari ? resumes : [...resumes, 'Upload a new resume']"
			/>

			<InputFile
				v-show="resumesCount === 0 || isSafari"
				ref="newResumeRef"
				label="Resume"
				v-model="resume.value.value"
				:error="resume.errorMessage.value"
			/>

			<ButtonLoader :loading="loading">
				Apply
			</ButtonLoader>
		</form>
	</div>
</template>

<script>
import {
	ref,
	computed,
	onMounted,
	watch
} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useForm, useField } from 'vee-validate'
import { useLoader } from '@/hooks'

import API_ERROR_CODES from '@/constants/apiErrorCodes'

import InputFile from '@/components/ui/InputFile.vue'
import ButtonLoader from '@/components/ui/ButtonLoader.vue'
import Select from '@/components/ui/Select.vue'

export default {
	components: { InputFile, ButtonLoader, Select },
	props: { message: String },

	setup() {
		const route = useRoute()
		const store = useStore()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit } = useForm()
		const resume = useField('resume')

		const error = computed(() => store.state.error)
		const resumes = computed(() => store.state.user.resumes)
		const job = computed(() => store.state.postedJobs.job)
		const user = computed(() => store.getters['user/userFullName'])
		const resumesCount = computed(() => store.getters['user/resumesCount'])

		const existingResume = ref()
		const newResumeRef = ref()
		const selectRef = ref()

		const { id } = route.params
		const isSafari = !!window.safari

		onMounted(
			() => store.dispatch('user/getResumes')
		)

		watch(
			() => resume.value.value,
			(newValue, oldValue) => {
				const { name } = resume.value.value

				if (name && oldValue === undefined)
					resumes.value.unshift(name)
				else
					resumes.value[0] = name

				existingResume.value = name
			}
		)

		watch(
			existingResume,
			() => {
				if (existingResume.value === 'Upload a new resume')
					newResumeRef.value.outerClick()
			}
		)

		const onSubmit = handleSubmit(
			async (values, actions) => {
				await proceedLoading(
					() => store.dispatch(
						'postedJobs/applyForJob',
						{
							jobId: +id,
							existingResume: existingResume.value || resumes.value[0],
							...values
						}
					)
				)

				if (error.value) {
					const { code } = error.value.response.data
					const handledCode = API_ERROR_CODES[code]

					return handledCode && actions.setFieldError(
						handledCode.field,
						handledCode.message
					)
				}

				if (job.value)
					return window.location.href = job.value.permalink
			}
		)

		return {
			loading,
			resumes,
			resumesCount,
			user,
			existingResume,
			newResumeRef,
			resume,
			selectRef,
			onSubmit,
			isSafari
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="ChooseResume"] {
	.choice {
		margin-bottom: 20px;
	}
}
</style>
