<template>
<div class="edit-user-container">
	<Wrapper v-if="!isLoading">
		<h2>My profile</h2>
		<!-- Add Breadcrumbs here -->
		<!-- <ul class="userBreadcrumbs">
			<li><a href="/dashboard/">Dashboard</a></li>
			<li class="separator"> / </li>
			<li>My profile</li>
		</ul> -->
		<!-- <User /> -->
		<div class="updateDashboardUser">
			<UpdateUser />
			<Consents />
		</div>
	</Wrapper>
</div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

import Wrapper from '@/components/dashboard/Wrapper.vue'
// import User from '@/components/dashboard/User.vue'
import UpdateUser from '@/components/dashboard/UpdateUser.vue'
import Consents from '@/components/dashboard/Consents.vue'

export default {
	components: {
		Wrapper,
		// User,
		UpdateUser,
		Consents
	},

	setup() {
		const store = useStore()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		onMounted(
			async () => {
				await store.dispatch('user/getUser')
				store.commit('setIsPageLoading', false)
			}
		)

		return { isLoading }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Wrapper"] {
	@include media('>=ipad') {
		grid-template-rows:  1fr 1fr;
	}
}

// [data-vue-component-name="User"] {

// 	@include media('>=tablet') {
// 		grid-column: 1/2;
// 		grid-row: 1/2;
// 	}
// }

[data-vue-component-name="Consents"] {
	order: 3;

	@include media('>=tablet') {
		grid-column: 1/2;
		grid-row: 2/3;
	}
}

[data-vue-component-name="Profile"] {
	order: 2;

	@include media('>=tablet') {
		grid-column: 2/4;
		grid-row: 1/3;
	}
}

</style>
