<template>
	<div data-vue-component-name="ResetPasswordStepOne" class="ResetPasswordStepOne">
		<p>
			Enter the email address associated with your account and we'll
			send you a code to reset your password.
		</p>

		<form @submit.prevent="onSubmit">
			<div class="email">
				<Link @click="$router.back()">Cancel</Link>

				<Input
					label="Email"
					type="email"
					v-model="email.value.value"
					:error="email.errorMessage.value"
				/>
			</div>

			<ButtonLoader :loading="loading">
				Send reset code
			</ButtonLoader>
		</form>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useForm, useField } from 'vee-validate'
import { useLoader } from '@/hooks'

import * as v from '@/helpers/validators'
import API_ERROR_CODES from '@/constants/apiErrorCodes'

import Link from '@/components/ui/Link.vue'
import Input from '@/components/ui/Input.vue'
import ButtonLoader from '@/components/ui/ButtonLoader.vue'

export default {
	components: { Link, Input, ButtonLoader },

	setup(props, ctx) {
		const store = useStore()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit } = useForm()
		const email = useField('email', v.email)

		const error = computed(() => store.state.error)

		const onSubmit = handleSubmit(
			async (values, actions) => {
				await proceedLoading(
					() => store.dispatch('user/resetPassword', values)
				)

				if (error.value) {
					const { code } = error.value.response.data
					const handledCode = API_ERROR_CODES[code]

					return handledCode && actions.setFieldError(
						handledCode.field,
						handledCode.message
					)
				}

				ctx.emit('nextStep', values.email)
			}
		)

		return { email, onSubmit, loading }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='ResetPasswordStepOne'] {
	margin-bottom: 30px;

	p {
		margin-bottom: 30px;
	}

	[data-vue-component-name='Input'] {
		margin-bottom: 30px;
	}

	.email {
		position: relative;

		[data-vue-component-name='Link'] {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
}
</style>
