<template>
	<label data-vue-component-name="Input">
		<span v-if="label" class="label">
			{{ label }}
		</span>

		<span v-if="type === 'password'" class="togglePw">&nbsp;</span>

		<input
			:class="{ error }"
			:type="type"
			:placeholder="placeholder"
			:value="modelValue"
			@input="onInputHandler"
		/>

		<span v-if="error" class="error">
			{{ error }}
		</span>
	</label>
</template>

<script>
export default {
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		label: String,
		error: String,
		placeholder: String,
		type: String
	},

	setup(props, ctx) {
		function onInputHandler(e) {
			const { value } = e.target

			ctx.emit('update:modelValue', value)
		}

		return { onInputHandler }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Input"] {
	position: relative;
	display: block;

	span {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.5;

		&.label {
			display: block;
			margin-bottom: 15px;
			color: $black;
		}

		&.error {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			color: red;
		}
	}

	input {
		width: 100%;
		padding: 8px 19px;
		color: $black;
		background-color: $white;
		border-radius: 100px;
		border: 1px solid $white;
		font-size: 12px;
		margin-bottom: 20px;

		&.error {
			border: 1px solid red;
		}
	}
}
</style>
