<template>
	<Paginate
		v-if="pageCount > 1"
		containerClass="pagination"
		pageClass="pagination__item"
		pageLinkClass="pagination__link"
		activeClass="current"
		prevText=""
		nextText=""
		:forcePage="activePage"
		:initialPage="activePage"
		:pageCount="pageCount"
		:clickHandler="changePage"
	/>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import scrollTo from '@/helpers/scrollTo'

import Paginate from 'vuejs-paginate-next'

export default {
	components: { Paginate },

	setup() {
		const store = useStore()

		const pageCount = computed(
			() => store.getters['postedJobs/pageCount']
		)

		const activePage = computed(
			() => store.state.postedJobs.activePage
		)

		async function changePage(page) {
			await store.dispatch('postedJobs/getJobs', page)

			scrollTo('jobs')
		}

		return { pageCount, activePage, changePage }
	}
}
</script>

<style lang="scss" scoped>
.pagination {
	display: flex;
	align-items: center;
	padding: 0;
	list-style: none;

	:deep(.pagination__item) {
		margin-left: 10px;
		cursor: pointer;

		&:last-of-type {
			margin-right: 0;
		}

		&.current {
			a {
				color: $white;
				background-color: $black;
			}
		}
	}

	:deep(.pagination__link) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		font-size: 10px;
		color: $black;
		background-color: $dark-grey;
		text-decoration: none;
		transition: background-color 0.25s ease, color 0.25s ease;

		&:hover {
			color: $white;
			background-color: $black;
		}
	}
}
</style>
