<template>
	<div data-vue-component-name="Register" class="registerUser">
		<div class="registerUserInnerContainer">
		<h1 class="title">
			Create an account {{ $route.params.id && 'to save your job' }}
		</h1>

		<form @submit.prevent="onSubmit">
			<Input
				placeholder="First name"
				v-model="name.value.value"
				:error="name.errorMessage.value"
			/>

			<Input
				placeholder="Last name"
				v-model="surname.value.value"
				:error="surname.errorMessage.value"
			/>

			<Input
				placeholder="Email"
				type="email"
				v-model="email.value.value"
				:error="email.errorMessage.value"
			/>

			<Input
				placeholder="Password"
				type="password"
				v-model="password.value.value"
				:error="password.errorMessage.value"
			/>

			<Input
				placeholder="Password confirmation"
				type="password"
				v-model="passwordConfirm.value.value"
				:error="passwordConfirm.errorMessage.value"
			/>

			<InputFile
				label="Resume"
				v-model="resume.value.value"
				:error="resume.errorMessage.value"
			/>

			<!-- <p>
				Equiliem is an equal opportunity employer. We honor
				diversity and are committed to creating an inclusive
				environment for everyone. Help us get to know you better
				by responding to these
				<b @click="() => optional.isOpen = true">
					optional questions
				</b>.
			</p>

			<div
				class="optional"
				v-if="optional.isOpen"
			>
				<Select
					label="Race/Ethnicity"
					v-model="optional.race"
					:options="OPTIONAL_QUESTIONS.race"
				/>

				<Select
					label="Gender"
					v-model="optional.gender"
					:options="OPTIONAL_QUESTIONS.gender"
				/>

				<Select
					label="Disability"
					v-model="optional.disability"
					:options="OPTIONAL_QUESTIONS.disability"
				/>

				<Select
					label="Protected Veteran"
					v-model="optional.protectedVeteran"
					:options="OPTIONAL_QUESTIONS.protectedVeteran"
				/>
			</div> -->

			<label class="checkbox">
				<Checkbox
					:checked="termsAgree.value.value"
					@click="termsAgree.setValue(!termsAgree.value.value)"
				/>
				<span :class="{ error: termsAgree.errorMessage.value }">
					By registering you agree to our
					<a href="/terms-conditions/" target="_blank">Terms and Conditions*</a>
				</span>
			</label>

			<ButtonLoader :loading="loading">
				Register
			</ButtonLoader>
		</form>

		<SignInLink />
		</div>
	</div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useForm, useField } from 'vee-validate'
import { useLoader } from '@/hooks'

import * as v from '@/helpers/validators'
import API_ERROR_CODES from '@/constants/apiErrorCodes'
import OPTIONAL_QUESTIONS from '@/constants/optionalQuestions'

import Input from '@/components/ui/Input.vue'
import InputFile from '@/components/ui/InputFile.vue'
// import Select from '@/components/ui/Select.vue'
import ButtonLoader from '@/components/ui/ButtonLoader.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import SignInLink from '../SignInLink.vue'

export default {
	components: {
		Input,
		InputFile,
		// Select,
		ButtonLoader,
		Checkbox,
		SignInLink
	},

	setup() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit } = useForm()
		const name = useField('name', v.defaultField)
		const surname = useField('surname', v.defaultField)
		const resume = useField('resume')
		const email = useField('email', v.email)
		const termsAgree = useField('termsAgree', v.check)
		const password = useField('password', v.password)
		const passwordConfirm = useField(
			'passwordConfirm', v.passwordConfirm(password.value)
		)

		// optional fields
		const {
			race,
			gender,
			disability,
			protectedVeteran
		} = OPTIONAL_QUESTIONS

		const optional = reactive({
			isOpen: false,
			race: race[0],
			gender: gender[0],
			disability: disability[0],
			protectedVeteran: protectedVeteran[0]
		})

		const error = computed(() => store.state.error)

		const onSubmit = handleSubmit(
			async (values, actions) => {
				const { termsAgree, ...mainData } = values
				const { isOpen, ...additionalData } = optional
				const userData = isOpen
					? { ...additionalData, ...mainData }
					: mainData

				await proceedLoading(
					() => store.dispatch('user/signUp', userData)
				)

				if (error.value) {
					const { code } = error.value.response.data
					const handledCode = API_ERROR_CODES[code]

					return handledCode && actions.setFieldError(
						API_ERROR_CODES[code].field,
						API_ERROR_CODES[code].message
					)
				}

				const { id, backTo } = route.params
				const { job } = store.state.postedJobs

				if (id) await store.dispatch('postedJobs/saveJob', +id)
				if (backTo) return router.push({ name: backTo })
				if (job) return window.location.href = job.permalink

				router.push({ name: 'PostedJobs' })
			}
		)

		return {
			name,
			surname,
			email,
			resume,
			termsAgree,
			password,
			passwordConfirm,
			optional,
			OPTIONAL_QUESTIONS,
			onSubmit,
			loading
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='Register'] {
	padding: 70px 36px 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.title {
		margin: 0 0 26px;
		font-size: 42px;
		font-weight: normal;
		line-height: 1.0714;
		color: $black;

		@include media('<tablet') {
			margin: 0 0 30px;
			font-size: 26px;
		}
	}

	form {
		margin-bottom: 30px;

		.checkbox {
			display: flex;
			align-items: center;
			font-size: 12px;
			margin-bottom: 30px;
			cursor: pointer;

			span.error {
				color: red;
			}

			a {
				color: $orange;
				text-decoration: none;
			}
		}

		p {
			font-size: 16px;
			margin-bottom: 30px;

			b {
				cursor: pointer;
			}
		}

		[data-vue-component-name='Input'],
		[data-vue-component-name='Select'] {
			margin-bottom: 10px;
		}

		[data-vue-component-name='ButtonLoader'] {
			min-width: 265px;

			@include media('<=tablet') {
				width: 100%;
			}
		}
	}

	.buttons {
		display: flex;
		align-items: center;
		padding: 0;
		list-style: none;

		@include media('<=tablet') {
			flex-direction: column;
			align-items: flex-start;
		}

		@include media('<tablet') {
			align-items: center;
		}

		li {
			display: flex;
			align-items: center;

			&:first-of-type {
				position: relative;
				margin-right: 30px;
				padding-right: 30px;

				@include media('<=tablet') {
					margin-right: 0;
					margin-bottom: 20px;
					padding-right: 0;
				}

				&:after {
					position: absolute;
					content: '';
					top: -2px;
					right: 0;
					bottom: -2px;
					width: 1px;
					background-color: $black;

					@include media('<=tablet') {
						display: none;
					}
				}
			}

			span {
				margin-right: 10px;
				font-size: 12px;
				font-weight: 600;
				line-height: 1.5;
			}
		}
	}
}
</style>
