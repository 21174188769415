<template>
	<Wrapper v-if="!isLoading">
		<div class="signin-container" v-if="!isLoggedIn">
			<!-- <Image
				class="left-slot"
				imgName="sign-in.jpg"
				figureColor="#b1ffe0"
			/> -->

			<div class="image-container"
				style="background-image:
				url(/wp-content/plugins/th-job-board/assets/img/sign-in.jpg)"
			>
			<picture>
				<img
					class="left-shot"
					alt ="profile"
					src="/wp-content/plugins/th-job-board/assets/img/sign-in.jpg"
				/>
			</picture>
			</div>
			<SignInApply class="right-slot" />
		</div>
		<div class="container applyForJobContainer" v-if="isLoggedIn">
			<SignInApply class="left-slot applyForJob" />
		</div>
	</Wrapper>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Wrapper from '@/components/entry/Wrapper.vue'
import SignInApply from '@/components/entry/form/SignInApply.vue'

export default {
	components: { Wrapper, SignInApply },

	setup() {
		const store = useStore()
		const route = useRoute()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		const isLoggedIn = computed(() => store.state.user.isLoggedIn)

		onMounted(
			async () => {
				if (route.params.id)
					await store.dispatch('postedJobs/getJob', +route.params.id)

				store.commit('setIsPageLoading', false)
			}
		)

		return {
			isLoading,
			isLoggedIn
		}
	}
}
</script>
