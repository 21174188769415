const OPTIONAL_QUESTIONS = {
	race: [
		'Hispanic or Latino',
		'White',
		'Black or African American',
		'Asian',
		'Native American or Alaska Native',
		'Two or more races',
		'I do not wish to disclose'
	],
	gender: [
		'Male',
		'Female',
		'Other',
		'I do not wish to disclose'
	],
	disability: [
		'I have a disability or have a history or record of having a disability',
		'I don’t have a disability or a history of record of having a disability',
		'I do not wish to disclose'
	],
	protectedVeteran: [
		'I identify as one or more of the classifications',
		'I am not a protected veteran',
		'I do not wish to disclose'
	]
}

export default OPTIONAL_QUESTIONS
