import api from '@/api'
import catchAsync from '@/helpers/catchAsync'

const initialState = () => ({
	jobAlerts: [],
	jobAlertsMeta: null
})

export default {
	namespaced: true,

	state: initialState(),

	getters: {
		alertsCount: state => state.jobAlertsMeta?.found_posts
	},

	mutations: {
		setJobAlerts(state, jobAlerts) {
			state.jobAlerts = jobAlerts
		},
		setJobAlertsMeta(state, jobAlertsMeta) {
			state.jobAlertsMeta = jobAlertsMeta
		},
		increaseAlertsCount(state) {
			state.jobAlertsMeta.found_posts += 1
		},
		decreaseAlertsCount(state) {
			state.jobAlertsMeta.found_posts -= 1
		}
	},

	actions: {
		getJobAlerts: catchAsync(
			async ({ commit }, count) => {
				const response = await api.getJobAlerts(count)
				const alerts = response.data.data
				const alertsMeta = response.data.meta

				commit('setJobAlerts', alerts)
				commit('setJobAlertsMeta', alertsMeta)
			}
		),
		getJobAlert: catchAsync(
			async (store, id) => {
				const response = await api.getJobAlert(id)
				const alert = response.data.data

				return alert
			}
		),
		saveJobAlert: catchAsync(
			async ({ dispatch, state, commit }) => {
				const alert = await dispatch('generateAlert')

				const response = await api.addJobAlert(alert)
				const newAlert = response.data.data
				const updatedAlerts = [newAlert].concat(state.jobAlerts)

				commit('setJobAlerts', updatedAlerts)
				commit('increaseAlertsCount')
			}
		),
		updateJobAlert: catchAsync(
			async ({ commit, dispatch, state }, id) => {
				const alert = await dispatch('generateAlert')

				const response = await api.updateJobAlert({ id, alert })
				const newAlert = response.data.data
				const updatedJobAlerts = state.jobAlerts.filter(
					alert => alert.id !== id
				)

				const updatedAlerts = [newAlert].concat(updatedJobAlerts)

				commit('setJobAlerts', updatedAlerts)
			}
		),
		deleteJobAlert: catchAsync(
			async ({ state, commit }, id) => {
				await api.deleteJobAlert(id)

				const updatedJobAlerts = state.jobAlerts.filter(
					alert => alert.id !== id
				)

				commit('setJobAlerts', updatedJobAlerts)
				commit('decreaseAlertsCount')
			}
		),
		generateAlert({ rootState }) {
			const alert = {
				search: rootState.filters.searchStr,
				...rootState.filters.selectedFilters
			}

			return alert
		}
	}
}
