import api from '@/api'
import catchAsync from '@/helpers/catchAsync'

const initialState = () => ({
	isLoggedIn: false,
	rtbfRequested: false,
	consents: null,
	resumes: [],
	user: null
})

export default {
	namespaced: true,

	state: initialState(),

	getters: {
		resumesCount: ({ resumes }) => resumes.length,
		userEmail: ({ user }) => user?.email,
		userFullName:
			({ user }) => user && `${user?.firstName} ${user?.lastName}`
	},

	mutations: {
		setUser(state, user) {
			state.user = user
		},
		setConsents(state, consents) {
			state.consents = consents
		},
		setRtbfRequested(state, rtbfRequested) {
			state.rtbfRequested = rtbfRequested
		},
		setIsLoggedIn(state, isLoggedIn) {
			state.isLoggedIn = isLoggedIn
		},
		setResumes(state, resumes) {
			state.resumes = resumes
		}
	},

	actions: {
		getUser: catchAsync(
			async ({ commit }) => {
				const response = await api.getUser()
				const { data, consents } = response.data

				commit('setUser', data)
				commit('setConsents', consents)
			}
		),
		getResumes: catchAsync(
			async ({ commit }) => {
				const response = await api.getResumes()
				const { data } = response

				commit('setResumes', data)
			}
		),
		uploadResume: catchAsync(
			async (store, resume) => {
				const formData = new FormData()
				formData.append('cv_file', resume)

				await api.uploadResume(formData)
			}
		),
		deleteResume: catchAsync(
			async ({ dispatch }, name) => {
				await api.deleteResume(name)

				dispatch('getResumes')
			}
		),
		updateUser: catchAsync(
			async ({ commit }, user) => {
				const nonce = window.php_vars?.th_nonce_handle || ''
				const userData = {
					first_name: user.firstName,
					last_name: user.lastName,
					email: user.email,
					nonce
				}

				const response = await api.updateUser(userData)
				const updatedUser = response.data.data

				commit('setUser', updatedUser)
				localStorage.setItem('th-user', JSON.stringify(updatedUser))
			}
		),
		requestRtbf: catchAsync(
			async ({ commit }) => {
				await api.requestRtbf()

				commit('setRtbfRequested', true)
			}
		),
		resetPassword: catchAsync(
			async (store, email) => {
				await api.resetPassword(email)
			}
		),
		setPassword: catchAsync(
			async (store, user) => {
				const userData = {
					code: user.code,
					email: user.email,
					pass: user.password,
					pass_repeat: user.passwordConfirm
				}

				await api.setPassword(userData)
			}
		),
		signUp: catchAsync(
			async ({ dispatch }, user) => {
				const formData = new FormData()

				formData.append('first_name', user.name)
				formData.append('last_name', user.surname)
				formData.append('email', user.email)
				formData.append('pass', user.password)
				formData.append('pass_repeat', user.passwordConfirm)
				formData.append('cv_file', user.resume)

				const nonce = window.php_vars?.th_nonce_handle || ''

				if (nonce)
					formData.append('nonce', nonce)
				const response = await api.signUp(formData)
				const loggedInUser = response.data.data

				await dispatch('saveTokenAndLogIn', loggedInUser)
			}
		),
		signIn: catchAsync(
			async ({ dispatch }, user) => {
				const nonce = window.php_vars?.th_nonce_handle || ''

				const userData = {
					username: user.email,
					password: user.password,
					nonce
				}

				const response = await api.signIn(userData)
				const loggedInUser = response.data.data

				await dispatch('saveTokenAndLogIn', loggedInUser)
			}
		),
		signInApply: catchAsync(
			async ({ dispatch }, user) => {
				const formData = new FormData()

				formData.append('job_id', user.jobId)
				formData.append('username', user.email)
				formData.append('password', user.password)
				formData.append('cv_file', user.resume)

				const nonce = window.php_vars?.th_nonce_handle || ''

				if (nonce)
					formData.append('nonce', nonce)

				const response = await api.signInApply(formData)
				const loggedInUser = response.data.data

				await dispatch('saveTokenAndLogIn', loggedInUser)
			}
		),
		registerApply: catchAsync(
			async ({ dispatch }, user) => {
				const formData = new FormData()

				formData.append('job_id', user.jobId)
				formData.append('first_name', user.name)
				formData.append('last_name', user.surname)
				formData.append('email', user.email)
				formData.append('pass', user.password)
				formData.append('pass_repeat', user.passwordConfirm)
				formData.append('cv_file', user.resume)

				if (user.nonce)
					formData.append('nonce', user.nonce)

				const response = await api.registerApply(formData)
				const loggedInUser = response.data.data

				await dispatch('saveTokenAndLogIn', loggedInUser)
			}
		),
		registerApplyGuest: catchAsync(
			async (store, user) => {
				const formData = new FormData()

				formData.append('job_id', user.jobId)
				formData.append('first_name', user.name)
				formData.append('last_name', user.surname)
				formData.append('email', user.email)
				formData.append('cv_file', user.resume)
				if (user.nonce)
					formData.append('nonce', user.nonce)
				await api.registerApplyGuest(formData)
			}
		),
		saveTokenAndLogIn: catchAsync(
			async ({ commit }, user) => {
				const { token, ...userInfo } = user

				localStorage.setItem('th-token', token)
				localStorage.setItem('th-user', JSON.stringify(userInfo))

				commit('setIsLoggedIn', true)
				commit('setUser', user)
			}
		),
		logOut: catchAsync(
			async ({ commit }) => {
				await api.logOut()

				localStorage.removeItem('th-token')
				localStorage.removeItem('th-user')

				commit('setIsLoggedIn', false)
				commit('setUser', null)
			}
		)
	}
}
