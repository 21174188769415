import { computed } from 'vue'
import { useStore } from 'vuex'

function useSelectedFilters(groupName) {
	const store = useStore()
	const subGroup = 'cities'

	const selectedFilters = computed(
		() => store.state.filters.selectedFilters[groupName] || []
	)

	function selectFilter(item) {
		if (!isNested(item))
			return toggleFilter(item)

		if (isSelected(item))
			item[subGroup].forEach(deleteFilter)
		else
			item[subGroup].forEach(addFilter)
	}

	function toggleFilter(item) {
		if (isSelected(item))
			deleteFilter(item)
		else
			addFilter(item)
	}

	function isSelected(item) {
		if (!isNested(item))
			return +isExist(item)

		const allSelected = item[subGroup].every(isExist)
		if (allSelected) return 1

		const someSelected = item[subGroup].some(isExist)
		if (someSelected) return 2

		return 0
	}

	function isNested(item) {
		return item[subGroup] && item[subGroup].length > 0
	}

	function isExist({ value }) {
		return selectedFilters.value.includes(value)
	}

	function addFilter({ value }) {
		selectedFilters.value.push(value)
	}

	function deleteFilter({ value }) {
		const index = selectedFilters.value.indexOf(value)

		if (index > -1)
			selectedFilters.value.splice(index, 1)
	}

	return {
		isSelected,
		selectFilter,
		selectedFilters: selectedFilters.value
	}
}

export default useSelectedFilters
