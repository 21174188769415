<template>
	<div data-vue-component-name="Resumes" class="resume-dashboard">
		<GreyBlank
			title="My Resumes"
			:count="resumesCount"
		>
			<div class="resumes-container">
				<CircleLoader v-if="loading" :loading="loading" />

				<div v-else-if="resumesCount > 0">
					<JobItem
						v-for="resume of resumes"
						:title="resume"
						:key="resume"
						@onDeleteHandler="deleteResume(resume)"
					/>
				</div>

				<p v-else class="no-resumes">There are no resumes yet</p>

				<div class="upload-resume-label">Upload your resume</div>
				<label for="resume" class="custom-file-input">
					<Button black full>Drag and drop your resume or <div>upload here</div></Button>

					<input
						type="file"
						id="resume"
						@change="e => uploadNewResume(e)"
					/>
				</label>
			</div>

			<Pagination
				:pageCount="pageCount"
				@onPageChange="onPageChange"
			/>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { usePagination, useLoader } from '@/hooks'

import Button from '@/components/ui/Button.vue'
import CircleLoader from '@/components/ui/CircleLoader.vue'
import Pagination from './Pagination.vue'
import GreyBlank from './GreyBlank.vue'
import JobItem from './JobItem.vue'

export default {
	components: {
		Button,
		CircleLoader,
		Pagination,
		GreyBlank,
		JobItem
	},

	props: {
		itemsPerPage: {
			type: Number,
			default: 2
		}
	},

	setup(props) {
		const resumesCount = computed(() => store.getters['user/resumesCount'])
		const userResumes = computed(() => store.state.user.resumes)

		const store = useStore()
		const [loading, proceedLoading] = useLoader()
		const [resumes, pageCount, onPageChange] = usePagination(
			userResumes,
			props.itemsPerPage
		)

		const uploadNewResume = async e => {
			const resume = e.target.files[0]

			await proceedLoading(
				async () => {
					await store.dispatch('user/uploadResume', resume)
					await store.dispatch('user/getResumes', resume)
				}
			)
		}

		const deleteResume = name => {
			store.dispatch('user/deleteResume', name)
		}

		return {
			loading,
			uploadNewResume,
			deleteResume,
			resumesCount,
			resumes,
			pageCount,
			onPageChange
		}
	}
}
</script>

<style lang="scss" scoped>
.resumes-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	[data-vue-component-name="JobItem"] {
		margin-bottom: 30px;

		:deep(a) {
			cursor: default;
			text-decoration: none;
		}
	}

	.no-resumes {
		font-size: 14px;
		text-align: center;
		margin-bottom: 30px;
	}

	.custom-file-input {
		cursor: pointer;

		&:hover [data-vue-component-name="Button"] {
			background: transparent;
			color: $black;
		}

		input {
			display: none;
		}

		[data-vue-component-name="Button"] {
			pointer-events: none;
		}
	}
}
</style>
