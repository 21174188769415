<template>
	<Wrapper>
		<div class="signin-container">
		<!-- <Image
			class="left-slot"
			imgName="sign-in.jpg"
			figureColor="#b1ffe0"
		/> -->

		<div class="image-container"
			style="background-image:
			url(/wp-content/plugins/th-job-board/assets/img/sign-in.jpg)"
		>
		<picture>
			<img
				class="left-shot"
				alt ="profile"
				src="/wp-content/plugins/th-job-board/assets/img/sign-in.jpg"
			/>
		</picture>
		</div>

		<ResetPassword class="right-slot" />
		</div>
	</Wrapper>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import Wrapper from '@/components/entry/Wrapper.vue'
// import Image from '@/components/entry/Image.vue'
import ResetPassword from '@/components/entry/form/ResetPassword.vue'

export default {
	components: { Wrapper, ResetPassword },

	setup() {
		const store = useStore()

		onMounted(
			() => store.commit('setIsPageLoading', false)
		)
	}
}
</script>
