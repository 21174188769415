<template>
	<div class="container">
		<div data-vue-component-name="Wrapper">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
[data-vue-component-name="Wrapper"] {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	grid-gap: 0;
	min-height: auto;
	padding: 20px 0 30px;
	background-color: $white;

	@include media('>=tablet') {
		grid-gap: 30px;
		grid-template-columns: 1fr 1fr;
	}

	@include media('>=ipad') {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows:  1fr 1fr;
		min-height: calc(100vh - 95px);
		padding: 60px 0 108px;
	}

	@include media('<tablet') {
		flex-direction: column;
		flex-wrap: nowrap;
	}
}
</style>
