<template>
	<div
		data-placeholder="Job title or keywords"
		data-vue-component-name="Search"
	>
		<div class="search-input">
			<input
				type="text"
				placeholder="Job title or keywords"
				v-model="jobName"
				@keyup.enter="findJobs"
			/>
		</div>
		<div class="search-dropdown">
			<div class="dropdown-handle">
				Location
			</div>
			<div class="location-dropdown-content">
				<FiltersGroup
					v-for="([key, value]) of Object.entries(filtersState)"
					:title="value.name"
					:group="value.values"
					:groupName="key"
					:key="value.name"
				/>
			</div>
		</div>

		<Button
			v-if="btnTitle"
			@click="findJobs"
		>
			{{ btnTitle }}
		</Button>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import scrollTo from '@/helpers/scrollTo'

import Button from '../ui/Button.vue'

import FiltersGroup from './FiltersGroup.vue'

export default {
	components: { Button, FiltersGroup },

	emits: ['onClickHandler'],
	props: {
		btnTitle: {
			type: String,
			default: 'Search'
		}
	},

	setup(props, ctx) {
		const store = useStore()

		const jobName = computed({
			get: () => store.state.filters.searchStr,
			set: val => store.commit('filters/setSearchStr', val)
		})

		const filtersState = computed(
			() => store.state.filters.filters
		)

		function findJobs() {
			ctx.emit(
				'onClickHandler',
				() => scrollTo('jobs')
			)
		}

		return {
			jobName,
			findJobs,
			filtersState
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Search"] {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px;
	font-size: 12px;
	background-color: #fff;
	border-radius: 100px;

	@include media ('<tablet') {
		position: relative;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		background-color: transparent;
	}

	input {
		flex-grow: 1;
		margin-right: 40px;
		padding-bottom: 7px;
		font-size: 12px;
		border: none;
		border-bottom: 1px solid $black;

		@include media('<tablet') {
			position: relative;
			margin: 0 0 15px;
			padding: 8px 19px;
			border: none;
			border-radius: 100px;
			width: 100%;
		}

		&::placeholder,
		&::-ms-input-placeholder,
		&:-ms-input-placeholder {
			color: $black;
			opacity: 1;
		}
	}
}
</style>
