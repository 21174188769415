<template>
	<div data-vue-component-name="Profile" class="updateUser">
		<GreyBlank title="My data">
			<form @submit.prevent="onSubmitHandler">
				<Input
					v-model="user.firstName"
					placeholder="First name"
					required
				/>

				<Input
					v-model="user.lastName"
					placeholder="Last name"
					required
				/>

				<Input
					v-model="user.email"
					placeholder="Email"
					type="email"
					required
				/>

				<Button>Save</Button>
			</form>
			<span class="back-dashboard-wrapper">Back to <a href="/dashboard/">Dashboard</a></span>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Button from '@/components/ui/Button.vue'
import Input from '@/components/ui/Input.vue'
import GreyBlank from './GreyBlank.vue'

export default {
	components: { Button, Input, GreyBlank },

	setup() {
		const store = useStore()

		const user = computed(
			() => ({ ...store.state.user.user })
		)

		function onSubmitHandler() {
			store.dispatch('user/updateUser', user.value)
		}

		return { user, onSubmitHandler }
	}
}
</script>

<style lang="scss" scoped>
form {
	width: 100%;
	color: $black;

	label {
		display: block;
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 40px;
		}

		span {
			display: block;
			margin-bottom: 15px;
			font-size: 12px;
			font-weight: 300;
			line-height: 1.5;
			color: $black;
		}

		input {
			width: 100%;
			background-color: $white;
			color: $black;
		}
	}

	[data-vue-component-name="Button"] {
		display: block;
		min-width: 256px;
		margin-left: auto;

		@include media('<=tablet') {
			min-width: inherit;
			width: 100%;
		}
	}
}
</style>
