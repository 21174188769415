<template>
	<div
		data-vue-component-name="FiltersCheckbox"
		:class="{ checked, sub }"
	>
		<div class="checkbox">
			<Checkbox
				@click="onClick"
				:id="filter.value"
				:checked="!!checked"
				:type="checked === 2 ? 'square' : 'check'"
			/>

			<label :for="filter.value">
				{{ filter.name }}
			</label>
		</div>

		<div class="jobs-count">
			<span>{{ filter.count }}</span>
		</div>
	</div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Checkbox from '../ui/Checkbox.vue'

export default {
	components: { Checkbox },

	emits: ['selectFilter'],
	props: { filter: Object, sub: Boolean, checked: Number },

	setup(props, ctx) {
		const store = useStore()
		const route = useRoute()

		function onClick() {
			ctx.emit('selectFilter', props.filter)

			if (route.name === 'PostedJobs')
				store.dispatch('postedJobs/getJobs')
		}

		return { onClick }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="FiltersCheckbox"] {
	display: flex;

	&.checked {
		.checkbox label,
		.jobs-count span {
			font-weight: 500;
		}
	}

	&.sub {
		.checkbox label {
			font-size: 10px;
		}

		.jobs-count span {
			font-size: 8px;
			min-width: 22px;
			height: 22px;
			padding: 5px;
		}
	}

	.checkbox {
		margin-right: 10px;
		min-width: 140px;
		display: flex;
		align-items: center;

		label {
			cursor: pointer;
			font-size: 12px;
			font-weight: 300;
		}
	}

	.jobs-count {
		min-width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: 30px;

		@include media('<tablet') {
			margin-left: 0;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 30px;
			height: 30px;
			padding: 5px;
			font-size: 12px;
			font-weight: 300;
			background: $dark-grey;
			border-radius: 50%;
		}
	}
}
</style>
