<template>
	<div data-vue-component-name="RegisterApply" class="registerApply">
		<div>
			<h1 class="title">
				{{ guest ? 'Apply without an account' : 'Create an account and apply' }}
			</h1>

			<p class="description">
				{{
					guest
						? 'Fill in the form below to apply for this job.'
						: `To apply for this job you’ll need an account.\n
	If you don’t have an account, we’ll create one for you.`
				}}
			</p>
		</div>

		<form @submit.prevent="onSubmit">
			<Input
				placeholder="First name"
				v-model="name.value.value"
				:error="name.errorMessage.value"
			/>

			<Input
				placeholder="Last name"
				v-model="surname.value.value"
				:error="surname.errorMessage.value"
			/>

			<Input
				placeholder="Email"
				type="email"
				v-model="email.value.value"
				:error="email.errorMessage.value"
			/>

			<Input
				v-if="!guest"
				placeholder="Password"
				type="password"
				v-model="password.value.value"
				:error="password.errorMessage.value"
			/>

			<Input
				v-if="!guest"
				placeholder="Password confirmation"
				type="password"
				v-model="passwordConfirm.value.value"
				:error="passwordConfirm.errorMessage.value"
			/>

			<InputFile
				label="Resume"
				v-model="resume.value.value"
				:error="resume.errorMessage.value"
			/>

			<label class="checkbox">
				<Checkbox
					:checked="cookieAgree.value.value"
					@click="cookieAgree.setValue(!cookieAgree.value.value)"
				/>
				<span :class="{ error: cookieAgree.errorMessage.value }">
					{{ guest ? 'I agree to the ' : 'By registering you agree to our ' }}
					<a href="/terms-conditions/" target="_blank">
						Terms and Conditions
					</a>
				</span>
			</label>

			<div class="left-slot mobile-only">
				<JobInfo />

				<p class="back">
					Back to
					<Link @click="$router.back()" medium> Job search </Link>
				</p>
			</div>

			<ButtonLoader :loading="loading"> Apply </ButtonLoader>
		</form>

		<SignInLink to="SignInApply" />
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useCookies } from 'vue3-cookies'
import { useForm, useField } from 'vee-validate'
import { useLoader } from '@/hooks'

import * as v from '@/helpers/validators'
import API_ERROR_CODES from '@/constants/apiErrorCodes'

import Input from '@/components/ui/Input.vue'
import InputFile from '@/components/ui/InputFile.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import ButtonLoader from '@/components/ui/ButtonLoader.vue'
import Link from '@/components/ui/Link.vue'
import JobInfo from '@/components/entry/JobInfo.vue'
import SignInLink from '../SignInLink.vue'

export default {
	components: {
		Input,
		InputFile,
		Checkbox,
		ButtonLoader,
		SignInLink,
		Link,
		JobInfo
	},

	setup() {
		const store = useStore()
		const {
			params: { id, guest }
		} = useRoute()
		const { cookies } = useCookies()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit } = useForm()
		const name = useField('name', v.defaultField)
		const surname = useField('surname', v.defaultField)
		const email = useField('email', v.email)
		const resume = useField('resume', v.file)
		const cookieAgree = useField('cookieAgree', v.check)
		const password = useField('password', guest ? true : v.password)
		const passwordConfirm = useField(
			'passwordConfirm',
			guest ? true : v.passwordConfirm(password.value)
		)

		const error = computed(() => store.state.error)

		const nonce = window.php_vars?.th_nonce_handle || ''

		const onSubmit = handleSubmit(async (values, actions) => {
			const { job } = store.state.postedJobs

			const { cookieAgree, ...userData } = values
			const user = { ...userData, jobId: +id, nonce }

			await proceedLoading(async () => {
				if (guest) {
					await store.dispatch('user/registerApplyGuest', user)
					cookies.set(`applied-th-job-${id}`, id, '30d')
				} else await store.dispatch('user/registerApply', user)
			})

			if (error.value) {
				const { code } = error.value.response.data
				const handledCode = API_ERROR_CODES[code]

				return (
					handledCode && actions.setFieldError(handledCode.field, handledCode.message)
				)
			}

			if (job) return (window.location.href = job.permalink)
		})

		return {
			name,
			surname,
			email,
			password,
			passwordConfirm,
			resume,
			cookieAgree,
			onSubmit,
			loading,
			guest
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='RegisterApply'] {
	padding: 40px 0 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.title {
		margin: 0 0 26px;
		font-size: 42px;
		font-weight: normal;
		line-height: 1.0714;
		color: $black;

		@include media('<tablet') {
			margin: 0 0 30px;
			font-size: 26px;
		}
	}

	.description {
		margin-bottom: 30px;
		white-space: pre;
	}

	form {
		margin-bottom: 30px;

		.checkbox {
			display: flex;
			align-items: center;
			font-size: 12px;
			margin-bottom: 30px;
			cursor: pointer;

			span.error {
				color: red;
			}

			a {
				color: $orange;
				text-decoration: none;
			}
		}

		[data-vue-component-name='Input'] {
			margin-bottom: 10px;
		}

		[data-vue-component-name='InputFile'] {
			margin-bottom: 15px;
		}

		[data-vue-component-name='ButtonLoader'] {
			min-width: 265px;

			@include media('<=tablet') {
				width: 100%;
			}
		}
	}

	.buttons {
		display: flex;
		align-items: center;
		padding: 0;
		list-style: none;

		@include media('<=tablet') {
			flex-direction: column;
			align-items: flex-start;
		}

		@include media('<tablet') {
			align-items: center;
		}

		li {
			display: flex;
			align-items: center;

			&:first-of-type {
				position: relative;
				margin-right: 30px;
				padding-right: 30px;

				@include media('<=tablet') {
					margin-right: 0;
					margin-bottom: 20px;
					padding-right: 0;
				}

				&:after {
					position: absolute;
					content: '';
					top: -2px;
					right: 0;
					bottom: -2px;
					width: 1px;
					background-color: $black;

					@include media('<=tablet') {
						display: none;
					}
				}
			}

			span {
				margin-right: 10px;
				font-size: 12px;
				font-weight: 600;
				line-height: 1.5;
			}
		}
	}
}
</style>
