<template>
	<div data-vue-component-name="JobAlerts" class="jobAlerts-dashboard">
		<GreyBlank
			title="Job alerts"
			:count="alertsCount"
		>
			<div class="alerts-container">
				<div
					class="alert"
					v-for="alert of alerts"
					:key="alert.id"
				>
					<JobItem
						:title="alert.title"
						@onClickHandler="onClickHandler(alert.id)"
						@onDeleteHandler="onDeleteHandler(alert.id)"
					/>

					<span
						class="edit-alert"
						@click="onEditHandler(alert.id)"
					>
						Edit
					</span>
				</div>

				<Button
					v-if="!alertsCount"
					class="no-alerts"
					bordered
					full
				>
					No job alerts
				</Button>

				<Button
					v-if="$route.name === 'DashboardAlerts'"
					class="create-alert"
					@click="onCreateHandler"
					black
				>
					Create alert
				</Button>

				<Link
					v-else
					bold
					@click="onCreateHandler"
					:to="{ name: 'DashboardAlerts' }"
				>
					Create job alerts
				</Link>
			</div>

			<Pagination
				:pageCount="pageCount"
				@onPageChange="onPageChange"
			/>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { usePagination } from '@/hooks'

import Button from '@/components/ui/Button.vue'
import Link from '@/components/ui/Link.vue'
import Pagination from './Pagination.vue'
import GreyBlank from './GreyBlank.vue'
import JobItem from './JobItem.vue'

export default {
	components: {
		Button,
		Link,
		Pagination,
		GreyBlank,
		JobItem
	},

	props: {
		itemsPerPage: {
			type: Number,
			default: 2
		}
	},

	setup(props) {
		const jobAlerts = computed(() => store.state.alerts.jobAlerts)
		const alertsCount = computed(() => store.getters['alerts/alertsCount'])

		const store = useStore()
		const router = useRouter()
		const [alerts, pageCount, onPageChange] = usePagination(
			jobAlerts,
			props.itemsPerPage
		)

		function onCreateHandler() {
			router.push({ name: 'AlertCreate' })
		}

		function onClickHandler(id) {
			router.push({ name: 'Alert', params: { id } })
		}

		function onEditHandler(id) {
			router.push({ name: 'AlertUpdate', params: { id } })
		}

		function onDeleteHandler(id) {
			store.dispatch('alerts/deleteJobAlert', +id)
		}

		return {
			alerts,
			alertsCount,
			pageCount,
			onPageChange,
			onCreateHandler,
			onClickHandler,
			onDeleteHandler,
			onEditHandler
		}
	}
}
</script>

<style lang="scss" scoped>
.alerts-container {
	display: flex;
	flex-direction: column;

	.alert {
		margin-bottom: 20px;
	}

	.edit-alert {
		font-size: 12px;
		font-weight: 300;
		cursor: pointer;
	}

	[data-vue-component-name="Button"] {
		&.no-alerts {
			margin-bottom: 30px;
		}

		&.create-alert {
			margin-left: auto;
		}
	}
}
</style>
