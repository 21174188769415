<template>
	<div class="filter__accordion" ref="accordion">
		<slot />
	</div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'

export default {
	emits: ['toggleAccordion'],

	setup(props, ctx) {
		onMounted(
			() => document.addEventListener('click', clickOutsideHandler)
		)

		onUnmounted(
			() => document.removeEventListener('click', clickOutsideHandler)
		)

		const accordion = ref()

		function clickOutsideHandler(event) {
			if (
				!accordion.value
				|| accordion.value.contains(event.target)
			) return

			if (event.target.dataset.name)
				return ctx.emit('toggleAccordion', event.target.dataset.name)

			ctx.emit('toggleAccordion', null)
		}

		return { accordion }
	}
}
</script>
