<template>
	<router-link
		data-vue-component-name="Link"
		:to="to"
		:class="{
			bold,
			medium,
			orange
		}"
	>
		<slot />
	</router-link>
</template>

<script>
export default {
	props: {
		bold: Boolean,
		medium: Boolean,
		orange: Boolean,

		to: {
			type: [Object, String],
			default: ''
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Link"] {
	padding: 0;
	font-size: 12px;
	font-weight: 300;
	line-height: 1.5;
	color: $black;

	&:hover {
		@include media('>=desktop') {
			text-decoration: none;
		}
	}

	&.bold {
		font-weight: 700;
	}

	&.medium {
		font-weight: 500;
	}

	&.orange {
		color: $orange;
	}
}
</style>
