<template>
	<div data-vue-component-name="Profile" class="profile-dashboard">
		<GreyBlank title="My Profile">
			<div class="profile-container">
				<div class="profile-item profileItemWithDropdown">
					<strong>{{ userFullName }}</strong>
				</div>

				<div class="profile-item last">
					<strong>{{ userEmail }}</strong>
				</div>

				<Button
					@click="$router.push({ name: 'DashboardUser' })"
					black
					full
				>
					Edit profile
				</Button>

				<div class="profileDropdown">
					<Link :to="{ name: 'ResetPassword' }">
						Reset Password
					</Link>

					<Link v-if="!rtbfRequested" @click="requestRtbf">
						Request RTBF
					</Link>

					<span class="sign-out" @click="signOut">
						<span>Log out</span>
					</span>
				</div>
			</div>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Link from '@/components/ui/Link.vue'
import Button from '@/components/ui/Button.vue'
import GreyBlank from './GreyBlank.vue'

export default {
	components: { Link, GreyBlank, Button },

	setup() {
		const store = useStore()

		const userFullName = computed(
			() => store.getters['user/userFullName']
		)

		const userEmail = computed(
			() => store.getters['user/userEmail']
		)
		const rtbfRequested = computed(
			() => store.state.user.rtbfRequested
		)
		async function requestRtbf() {
			await store.dispatch('user/requestRtbf')
		}
		async function signOut() {
			await store.dispatch('user/logOut')
		}
		return {
			userFullName,
			userEmail,
			rtbfRequested,
			requestRtbf,
			signOut
		}
	}
}
</script>

<style lang="scss" scoped>
.profile-container {
	color: $black;

	.profile-item {
		margin-bottom: 10px;
		font-size: 12px;
		line-height: 1.5;

		&.last {
			margin-bottom: 30px;
		}

		span {
			margin-right: 15px;
			font-weight: 300;
		}

		strong {
			font-weight: 600;
		}
	}
}
</style>
