<template>
	<div data-vue-component-name="JobsListItem" class="jobs-card">
		<div class="jobs-card__wrapper">
			<div class="job-card-title__container">
				<h2 class="jobs-card__title" @click="readMore">
					{{ job.title }}
				</h2>

				<div class="jobs-card__info">
					<span class="job-salary" v-if="job.salary">
						{{ formattedSalary(job.salary) }}
					</span>
					<strong>
						{{ job.location }}
						<!-- {{ formattedJobTitle(job.city, job.state) }} -->
					</strong>
					<span>Posted {{ job.date_str }}</span>
				</div>
			</div>

			<div class="jobs-card__description">
				<p>{{ job.excerpt }}</p>
			</div>

			<div class="jobs-card__btns-wrapper">
				<Button
					:inactive="job.is_saved"
					:purple="!job.is_saved"
					@click="saveJob"
				>
					Save job
				</Button>

				<Button
					:inactive="job.is_applied || appliedByInactiveUser"
					:orange="!job.is_applied"
					@click="applyNow"
				>
					Apply now
				</Button>

				<Button @click="readMore" black> Read more </Button>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useCookies } from 'vue3-cookies'
import formattedJobTitle from '@/helpers/formattedJobTitle'
import formattedSalary from '@/helpers/formattedSalary'

import Button from '../ui/Button.vue'

export default {
	components: { Button },
	props: { job: Object },

	setup(props) {
		const store = useStore()
		const router = useRouter()
		const route = useRoute()
		const { cookies } = useCookies()

		const isLoggedIn = computed(() => store.state.user.isLoggedIn)

		const params = { id: +props.job.id, backTo: route.name }
		const appliedByInactiveUser = cookies.isKey(
			`applied-th-job-${props.job.id}`
		)

		function saveJob() {
			if (!isLoggedIn.value) return router.push({ name: 'Register', params })

			store.dispatch('postedJobs/saveJob', params.id)
		}

		function applyNow() {
			if (!props.job.is_applied && !appliedByInactiveUser)
				router.push({ name: 'ApplyForJob', params })
		}

		async function readMore() {
			await store.dispatch('saveSessionInfo')
			window.location.href = props.job.permalink
		}

		return {
			saveJob,
			applyNow,
			readMore,
			formattedJobTitle,
			formattedSalary,
			appliedByInactiveUser
		}
	}
}
</script>

<style lang="scss" scoped>
.jobs-card {
	padding: 30px 0 40px;

	@include media('<=ipad') {
		padding: 30px 0;
	}

	&__title {
		margin: 0 0 16px;
		font-size: 42px;
		font-weight: normal;
		line-height: 1.0714;
		color: $black;
		cursor: pointer;

		@include media('<=tablet') {
			margin: 0 0 20px;
			font-size: 26px;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		font-size: 16px;
		line-height: 1.4375;
		color: $black;

		@include media('<=tablet') {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 20px;
		}

		strong {
			position: relative;
			margin-right: 40px;
			font-weight: 600;

			@include media('<=tablet') {
				margin-right: 0;
				margin-bottom: 5px;
			}

			&:after {
				position: absolute;
				content: '';
				right: -20px;
				top: -3px;
				bottom: -3px;
				width: 1px;
				background-color: $black;

				@include media('<=tablet') {
					display: none;
				}
			}
		}
	}

	&__description {
		margin-bottom: 30px;
		font-size: 16px;
		font-weight: normal;
		line-height: 1.3125;
		color: $black;
		white-space: normal;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		@include media('<=ipad') {
			-webkit-line-clamp: 3;
		}

		@include media('<=phone') {
			-webkit-line-clamp: 6;
		}

		p {
			margin: 0 0 16px;

			&:last-of-type {
				margin: 0;
			}
		}
	}

	&__btns-wrapper {
		display: flex;
		flex-wrap: wrap;

		@include media('<=tablet') {
			padding-right: 30%;
		}
	}

	[data-vue-component-name='Button'] {
		margin-right: 10px;

		@include media('<=ipad') {
			margin-bottom: 10px;
			min-width: 161px;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
