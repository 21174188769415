<template>
	<div v-if="!isLoading">
		<div class="filtersContainer">
			<Wrapper>
				<h1>Find your next job.</h1>

				<Search @onClickHandler="onClickHandler" />
				<Filters />
			</Wrapper>
		</div>

		<JobsList />
	</div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

import Filters from '@/components/filters/Filters.vue'
import Search from '@/components/filters/Search.vue'
import Wrapper from '@/components/filters/Wrapper.vue'
import JobsList from '@/components/posted-jobs/JobsList.vue'

export default {
	components: {
		Search,
		Filters,
		JobsList,
		Wrapper
	},

	setup() {
		const store = useStore()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		onMounted(
			async () => {
				const { scrollPos } = await store.dispatch('applySessionInfo')
				await store.dispatch('postedJobs/getJobs')
				await store.commit('setIsPageLoading', false)

				window.scrollTo(0, scrollPos || 0)
			}
		)

		async function onClickHandler(scroll) {
			await store.dispatch('postedJobs/getJobs')

			scroll()
		}

		return { onClickHandler, isLoading }
	}
}
</script>

<style lang="scss" scoped>
h1 {
	font-size: 42px;
	margin: 40px 0;
	text-align: center;

	@include media('<tablet') {
		font-size: 32px;
	}
}
</style>
