<template>
	<div data-vue-component-name="JobItem">
		<Link
			@click="$emit('onClickHandler')"
			bold
		>
			{{ title }}
		</Link>

		<IconCross @click="$emit('onDeleteHandler')" />
	</div>
</template>

<script>
import Link from '../ui/Link.vue'
import IconCross from '../icons/IconCross.vue'

export default {
	components: { Link, IconCross },

	emits: ['onDeleteHandler', 'onClickHandler'],
	props: { edit: Boolean, title: String }
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="JobItem"] {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	&:last-of-type {
		margin-bottom: 0;
	}

	[data-vue-component-name="Link"] {
		display: -webkit-box;
		padding-right: 60px;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
</style>
