<template>
	<div
		data-vue-component-name="ApplyForJob"
		:style="{ justifyContent: isLoggedIn ? 'space-between' : 'center' }"
	>

		<div class="chooseResume">
		<JobInfo />
		<UserSignOut />

		<ChooseResume
			v-if="isLoggedIn"
			message="Choose your resume from the list below or upload a new resume."
		/>

		<div class="additional-buttons" v-if="!isLoggedIn">
			<Button @click="applyAndLogin">Sign in and apply</Button>
			<Button @click="applyAndRegister">Create an account and apply</Button>
			<Button @click="applyWithoutAcc">Apply without an account</Button>
		</div>

		<p class="back">
			Back to
			<Link @click="$router.back()" medium> job search </Link>
		</p>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import { useLoader } from '@/hooks'

import * as v from '@/helpers/validators'
import API_ERROR_CODES from '@/constants/apiErrorCodes'

import Link from '@/components/ui/Link.vue'
import Button from '@/components/ui/Button.vue'
import UserSignOut from '../UserSignOut.vue'
import JobInfo from '../JobInfo.vue'
import ChooseResume from '../ChooseResume.vue'

export default {
	components: {
		Link,
		Button,
		UserSignOut,
		JobInfo,
		ChooseResume
	},

	setup() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit } = useForm()
		const resume = useField('resume', v.file)

		const error = computed(() => store.state.error)
		const isLoggedIn = computed(() => store.state.user.isLoggedIn)

		const { id: jobId } = route.params

		const onSubmit = handleSubmit(
			async (values, actions) => {
				await proceedLoading(
					() => store.dispatch(
						'postedJobs/applyForJob',
						{ jobId: +jobId, ...values }
					)
				)

				if (error.value) {
					const code = error.value.response.data.code
					const handledCode = API_ERROR_CODES[code]

					return handledCode && actions.setFieldError(
						handledCode.field,
						handledCode.message
					)
				}
			}
		)

		const applyAndLogin = () => router.push({
			name: 'SignInApply',
			params: { id: jobId }
		})

		const applyAndRegister = () => router.push({
			name: 'RegisterApply',
			params: { id: jobId }
		})

		const applyWithoutAcc = () => router.push({
			name: 'RegisterApply',
			params: { id: jobId, guest: 'guest' }
		})

		return {
			resume,
			onSubmit,
			applyWithoutAcc,
			applyAndRegister,
			applyAndLogin,
			loading,
			isLoggedIn
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="ApplyForJob"] {
	padding: 40px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.additional-buttons {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		justify-content: center;
		max-width: 350px;

		[data-vue-component-name="Button"] {
			margin-bottom: 15px;

			@include media('<=desktop') {
				margin-right: 0;
				margin-bottom: 15px;
			}
		}

		@include media('<=desktop') {
			flex-direction: column;
		}
	}

	.back {
		font-size: 12px;
		font-weight: 500;
	}

	@include media('<=desktop') {
		[data-vue-component-name="JobInfo"] {
			padding-top: 0;
		}
	}
}
</style>
