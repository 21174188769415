<template>
	<div
		data-vue-component-name="Wrapper"
		:class="{ bordered }"
	>
		<slot />
	</div>
</template>

<script>
export default {
	props: { bordered: Boolean }
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Wrapper"] {
	background-color: $light-grey;
	display: flex;
	min-height: calc(100vh - 70px);

	@include media('<tablet') {
		flex-direction: column;
	}

	@include media('>=desktop') {
		min-height: calc(100vh - 95px);
	}

	:slotted(.left-slot),
	:slotted(.right-slot) {
		width: 50%;
		padding: 40px;

		@include media('<tablet') {
			width: 100%;
		}
	}

	&.bordered {
		padding: 40px 0;

		:slotted(.left-slot) {
			position: relative;

			&:after {
				position: absolute;
				content: '';
				top: 0;
				right: 0;
				bottom: 0;
				width: 1px;
				background-color: $black;

				@include media('<tablet') {
					top: inherit;
					left: 20px;
					right: 20px;
					bottom: 0;
					width: auto;
					height: 1px;
				}
			}
		}
	}
}
</style>
