<template>
	<div data-vue-component-name="Consents" class="consents">
		<GreyBlank title="My consents">
			<div class="consents-list">
				<div
					class="consents-list__item"
					v-for="item of consents"
					:key="item.url"
				>
					<span>{{ item.link_title }}</span>
					<a class="link" :href="item.url">Details</a>
				</div>
			</div>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import GreyBlank from './GreyBlank.vue'

export default {
	components: { GreyBlank },

	setup() {
		const store = useStore()

		const consents = computed(
			() => store.state.user.consents
		)

		return { consents }
	}
}
</script>

<style lang="scss" scoped>
.consents-list {
	margin: 0 0 30px;
	padding: 0;
	list-style: none;

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 20px;

		&:last-of-type {
			margin-bottom: 0;
		}

		a {
			font-size: 12px;
			font-weight: 300;
			color: $black;

			&:hover {
				@include media('>=desktop') {
					text-decoration: none;
				}
			}
		}

		span {
			display: block;
			margin-bottom: 6px;
			font-size: 12px;
			font-weight: 600;
			line-height: 1.5;
		}
	}
}
</style>
