<template>
	<div data-vue-component-name="JobInfo">
		<p class="description">
			You are applying for:
		</p>

		<h1 class="job-name">
			{{ appliedJob.title }}
		</h1>

		<div class="job-info">
			<span class="city">
				{{ formattedJobTitle(appliedJob.city, appliedJob.state) }}
			</span>
			<span class="date" v-if="!isLoggedIn">
				Posted on
				{{ appliedJob.date && formattedDate(appliedJob.date) }}
			</span>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import formattedJobTitle from '@/helpers/formattedJobTitle'

export default {
	setup() {
		const store = useStore()

		const isLoggedIn = computed(() => store.state.user.isLoggedIn)

		const appliedJob = computed(
			() => store.state.postedJobs.job || {}
		)

		function formattedDate(dateStr) {
			const date = new Date(dateStr)

			const day = date.getDate()
			const year = date.getFullYear()
			const month = date.toLocaleString('default', { month: 'long' })

			return `${month} ${day}, ${year}`
		}

		return {
			appliedJob,
			formattedDate,
			formattedJobTitle,
			isLoggedIn
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="JobInfo"] {
	position: relative;

	@include media('<=tablet') {
		padding: 48px 20px 25px;
	}

	.job-name {
		font-size: 42px;
		font-weight: normal;
		line-height: 1.0714;
		color: $black;
		margin-bottom: 20px;

		@include media('<=tablet') {
			font-size: 26px;
		}
	}

	.description {
		margin-bottom: 20px;

		@include media('<tablet') {
			margin-bottom: 30px;
		}
	}

	.job-info {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		span {
			font-size: 16px;
			font-weight: 300;
			line-height: 1.4375;

			&:first-of-type {
				margin-right: 40px;
				font-weight: 600;
				position: relative;

				&:after {
					position: absolute;
					content: '';
					top: -5px;
					right: -20px;
					bottom: -5px;
					width: 1px;
					background-color: $black;
				}
			}
		}
	}

	.back {
		margin-top: auto;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.5;

		[data-vue-component-name="Link"] {
			font-weight: 600;
		}
	}
}
</style>
