<template>
	<div class="signin-container" v-if="!isLoggedIn">
		<div class="image-container"
			style="background-image:
			url(/wp-content/plugins/th-job-board/assets/img/sign-in.jpg)"
		>
		<picture>
			<img
				class="left-shot"
				alt ="profile"
				src="/wp-content/plugins/th-job-board/assets/img/sign-in.jpg"
			/>
		</picture>
		</div>
		<SignIn class="right-slot" />
	</div>
	<div class="dashboard-container" v-if="isLoggedIn">
		<Wrapper v-if="!isLoading">
			<!-- <User /> -->
			<h2>Dashboard</h2>
			<Profile />
			<Resumes :itemsPerPage="3" />
			<SavedJobs :itemsPerPage="20" />
			<JobAlerts :itemsPerPage="10" />
			<SuggestedJobs :itemsPerPage="6" />
		</Wrapper>
	</div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

import Wrapper from '@/components/dashboard/Wrapper.vue'
import SavedJobs from '@/components/dashboard/SavedJobs.vue'
import JobAlerts from '@/components/dashboard/JobAlerts.vue'
import SuggestedJobs from '@/components/dashboard/SuggestedJobs.vue'
// import User from '@/components/dashboard/User.vue'
import Profile from '@/components/dashboard/Profile.vue'
import Resumes from '@/components/dashboard/Resumes.vue'
import SignIn from '@/components/entry/form/SignIn.vue'

export default {
	components: {
		Wrapper,
		// User,
		Profile,
		SavedJobs,
		JobAlerts,
		SuggestedJobs,
		SignIn,
		Resumes
	},

	setup() {
		const store = useStore()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		const isLoggedIn = computed(() => store.state.user.isLoggedIn)

		onMounted(
			async () => {
				await Promise.all([
					store.dispatch('alerts/getJobAlerts'),
					store.dispatch('userJobs/getSuggestedJobs'),
					store.dispatch('userJobs/getJobs'),
					store.dispatch('user/getResumes')
				])

				store.commit('setIsPageLoading', false)
			}
		)

		return {
			isLoading,
			isLoggedIn
		}
	}
}
</script>

<style lang="scss" scoped>
h2{
	grid-column: 1/4;
	grid-row: 1/2;
}
[data-vue-component-name="Wrapper"] {
	@include media('height>=900px') {
		height: 100vh;
	}
}

// [data-vue-component-name="User"] {
// 	order: 1;

// 	@include media('>=tablet') {
// 		order: inherit;
// 		grid-column: 1/3;
// 		grid-row: 1/2;
// 	}

// 	@include media('>=ipad') {
// 		grid-column: 1/2;
// 		grid-row: 1/3;
// 	}
// }

[data-vue-component-name="JobAlerts"] {
	order: 2;

	@include media('>=tablet') {
		order: inherit;
		grid-column: 1/2;
		grid-row: 3/4;
	}

	@include media('>=ipad') {
		grid-column: 1/2;
		grid-row: 3/4;
	}
}

[data-vue-component-name="Profile"] {
	order: 3;

	@include media('>=tablet') {
		order: inherit;
		grid-column: 1/2;
		grid-row: 2/3;
	}

	@include media('>=ipad') {
		grid-column: 1/2;
		grid-row: 2/3;
	}
}

[data-vue-component-name="SuggestedJobs"] {
	order: 4;

	@include media('>=tablet') {
		order: inherit;
		grid-column: 2/3;
		grid-row: 3/4;
	}

	@include media('>=ipad') {
		grid-column: 2/3;
		grid-row: 3/4;
	}
}

[data-vue-component-name="Resumes"] {
	order: 5;

	@include media('>=tablet') {
		order: inherit;
		grid-column: 1/2;
		grid-row: 3/4;
	}

	@include media('>=ipad') {
		grid-column: 3/4;
		grid-row: 1/3;
	}
}

[data-vue-component-name="SavedJobs"] {
	order: 6;

	@include media('>=tablet') {
		order: inherit;
		grid-column: 3/4;
		grid-row: 3/4;
	}

	@include media('>=ipad') {
		grid-column: 3/4;
		grid-row: 3/4;
	}

	:deep([data-vue-component-name="JobItem"]):last-of-type {
		margin-bottom: 30px;

		@include media('>=tablet') {
			margin-bottom: 150px;
		}
	}
}
</style>
