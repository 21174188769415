<template>
	<button
		data-vue-component-name="Button"
		:class="{
			full,
			orange,
			purple,
			black,
			bordered,
			inactive
		}"
	>
		<slot />
	</button>
</template>

<script>
export default {
	props: {
		full: Boolean,

		// colors
		orange: Boolean,
		purple: Boolean,
		black: Boolean,
		bordered: Boolean,
		inactive: Boolean
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Button"] {
	font-size: 16px;
	font-weight: 600;
	padding: 6px 35px;
	border-radius: 100px;
	cursor: pointer;
	background-color: $orange;
	border: 2px solid $orange;
	color: $white;
	transition:
		color 0.25s ease,
		background-color 0.25s ease,
		border-color 0.25s ease;

	&:hover {
		@include media('>=desktop') {
			color: $orange;
			background-color: transparent;
		}
	}

	&.full {
		width: 100%;
	}

	&.orange {
		background-color: $orange;
		border-color: $orange;

		&:hover {
			@include media('>=desktop') {
				background-color: transparent;
				color: $orange;
			}
		}
	}

	&.purple {
		background-color: $purple;
		border-color: $purple;

		&:hover {
			@include media('>=desktop') {
				background-color: transparent;
				color: $purple;
			}
		}
	}

	&.black {
		background-color: $black;
		border-color: $black;

		&:hover {
			@include media('>=desktop') {
				background-color: transparent;
				color: $black;
			}
		}
	}

	&.bordered {
		background-color: transparent;
		border-color: $black;
		color: $black;
		cursor: auto;
	}

	&.inactive {
		background: $dark-grey;
		border: 2px solid $dark-grey;
		cursor: auto;

		&:hover {
			@include media('>=desktop') {
				background: $dark-grey;
				color: $white;
			}
		}
	}
}
</style>
