<template>
	<div data-vue-component-name="Wrapper">
		<div class="container">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.container {
	@include media('<=ipad') {
		padding: 0 20px;
	}
}

[data-vue-component-name="Wrapper"] {
	background: $light-grey;
	padding-top: 10px;
	padding-bottom: 30px;

	:slotted([data-vue-component-name="Search"]) {
		margin-bottom: 40px;
	}
}
</style>
