<template>
	<div data-vue-component-name="GreyBlank">
		<div v-if="title" class="title-box">
			<h2>{{ title }}</h2>

			<span v-if="count" class="counter">
				{{ count }}
			</span>
		</div>

		<slot />
	</div>
</template>

<script>
export default {
	emits: ['onPageChange'],
	props: { title: String, count: Number }
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="GreyBlank"] {
	padding: 35px 40px;
	background-color: $light-grey;
	height: 100%;
	min-height: 365px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@include media('<=ipad') {
		width: calc(50% - 10px);
		margin-bottom: 20px;

		@include media('<tablet') {
			width: 100%;
		}

		&:first-of-type {
			width: 100%;
		}
	}

	.title-box {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $black;
		margin-bottom: 25px;
		padding-bottom: 25px;
		min-height: 30px;
		box-sizing: content-box;

		h2 {
			margin: 0 16px 0 0;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.4375;

			&:only-child {
				margin-right: 0;
			}
		}

		.counter {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			margin-left: auto;
			font-size: 10px;
			font-weight: 300;
			color: $black;
			background-color: $dark-grey;
			border-radius: 50%;
		}
	}
}
</style>
