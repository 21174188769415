<template>
	<div data-vue-component-name="Filters">
		<div class="filters-top">
			<span @click="clearFilters">
				Clear all filters
			</span>
		</div>

		<div class="filters-container">
			<FiltersGroup
				v-for="([key, value]) of Object.entries(filters)"
				:title="value.name"
				:group="value.values"
				:groupName="key"
				:key="value.name"
			/>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import FiltersGroup from './FiltersGroup.vue'

export default {
	components: { FiltersGroup },

	setup() {
		const store = useStore()
		const route = useRoute()

		const filters = computed(
			() => {
				console.log(store.state.filters.filters.industries)
				return store.state.filters.filters
			}
		)

		async function clearFilters() {
			await store.dispatch('filters/clearFilters')

			if (route.name === 'PostedJobs')
				await store.dispatch('postedJobs/getJobs')
		}

		return {
			filters,
			clearFilters
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Filters"] {
	border-top: 1px solid $black;
	padding-top: 10px;

	.filters-top {
		display: flex;
		justify-content: right;

		span {
			font-size: 12px;
			cursor: pointer;
		}
	}

	.filters-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-top: 16px;

		@include media('<tablet') {
			display: flex;
			flex-direction: column;
		}

		[data-vue-component-name="FiltersGroup"] {
			@include media('<tablet') {
				margin-bottom: 30px;
				padding-bottom: 30px;
				border-bottom: 1px solid $black;
			}

			&:first-child{
				grid-column: 1;
				grid-row: 1;

				@include media('<=ipad') {
					grid-column: 1 / 2;
				}
			}

			&:nth-child(2) {
				grid-column: 2;
				grid-row: 1;

				@include media('<=ipad') {
					grid-column: 2 / 3;
					grid-row: 1;
				}
			}

			&:nth-child(3) {
				/* position: relative; */
				/* padding-top: 35px; */
				grid-column: 2/4;
				grid-row: 1/4;
				-moz-columns: 3;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				align-content: baseline;
				justify-content: center;
				/* columns: 3; */

				@include media('<=ipad') {
					grid-column: 1 / 4;
					grid-row: 2 / 4;
				}

				@include media('<tablet') {
					columns: 1;
					border-bottom: none;
				}
			}
		}
	}
}
</style>
