<template>
	<Wrapper v-if="!isLoading" bordered>
		<div class="register-container container">
			<div class="left-slot">
				<JobInfo />

				<p class="back">
					Back to
					<Link @click="$router.back()" medium> Job search </Link>
				</p>
			</div>
			<RegisterApply class="right-slot" />
		</div>
	</Wrapper>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Link from '@/components/ui/Link.vue'
import Wrapper from '@/components/entry/Wrapper.vue'
import JobInfo from '@/components/entry/JobInfo.vue'
import RegisterApply from '@/components/entry/form/RegisterApply.vue'

export default {
	components: {
		Link,
		Wrapper,
		JobInfo,
		RegisterApply
	},

	setup() {
		const store = useStore()
		const route = useRoute()

		const isLoading = computed(() => store.state.isPageLoading)

		onMounted(async () => {
			if (route.params.id)
				await store.dispatch('postedJobs/getJob', +route.params.id)

			store.commit('setIsPageLoading', false)
		})

		return { isLoading }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name='Wrapper'] {
	.left-slot {
		display: flex;
		flex-direction: column;

		[data-vue-component-name='JobInfo'] {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: center;
		}

		.back {
			font-size: 12px;
			font-weight: 500;
			margin-top: auto;
		}
	}
}
</style>
