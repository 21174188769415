<template>
	<span data-vue-component-name="IconCross" />
</template>

<style lang="scss" scoped>
[data-vue-component-name="IconCross"] {
	cursor: pointer;
	position: relative;
	display: block;
	min-width: 17px;
	min-height: 17px;
	padding: 0;
	border: none;
	background-color: transparent;

	&:before,
	&:after{
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		width: 2px;
		height: 10px;
		background-color: $black;
	}

	&:before {
		top: 4px;
		left: 7px;
		transform: rotate(45deg);
	}

	&:after {
		top: 4px;
		left: 7px;
		transform: rotate(-45deg);
	}
}
</style>
