<template>
	<label data-vue-component-name="Select">
		<span v-if="label" class="label">
			{{ label }}
		</span>

		<div class="select-container">
			<select @change="onChangeHandler">
				<option
					v-for="(option, idx) of options"
					:selected="idx === 0"
					:key="option"
				>
					{{ option }}
				</option>
			</select>
		</div>
	</label>
</template>

<script>
export default {
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		options: Array,
		label: String
	},

	setup(props, ctx) {
		function onChangeHandler(e) {
			const { value } = e.target

			ctx.emit('update:modelValue', value)
		}

		ctx.expose({ onChangeHandler })

		return { onChangeHandler }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Select"] {
	position: relative;
	overflow: auto;
	display: block;

	span {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.5;

		&.label {
			display: block;
			margin-bottom: 15px;
			color: $black;
		}

		&.error {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			color: red;
		}
	}

	.select-container {
		width: 100%;
		margin-bottom: 20px;
		border-radius: 100px;
		position: relative;

		&:before {
			content: '';
			background: url('~@/assets/images/arrowhead.svg') no-repeat center;
			background-size: 15px 15px;
			transform: rotate(270deg);
			position: absolute;
			right: 19px;
			top: 10px;
			width: 15px;
			height: 15px;
			pointer-events: none;
		}

		select {
			padding: 8px 19px;
			border-radius: 100px;
			border: 1px solid $white;
			width: inherit;
			font-size: 12px;
			color: $black;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;

			option {
				background: $white;
			}

			&.error {
				border: 1px solid red;
			}
		}
	}
}
</style>
