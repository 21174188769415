<template>
	<div class="filtersContainer jobAlerts">
	<Wrapper v-if="!isLoading">
		<h1>Update job alert</h1>
		<Search
			btnTitle="Update job alert"
			@onClickHandler="onClickHandler"
		/>

		<Filters />
	</Wrapper>
	</div>
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import Search from '@/components/filters/Search.vue'
import Filters from '@/components/filters/Filters.vue'
import Wrapper from '@/components/filters/Wrapper.vue'

export default {
	components: { Search, Filters, Wrapper },

	setup() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		onMounted(
			async () => {
				const alert = await store.dispatch(
					'alerts/getJobAlert',
					+route.params.id
				)

				await store.dispatch('filters/applyFilters', alert)
				store.commit('setIsPageLoading', false)
			}
		)

		onUnmounted(
			async () => {
				await Promise.all([
					store.dispatch('filters/clearFiltersAndSearch'),
					store.dispatch('postedJobs/clearJobs')
				])
			}
		)

		async function onClickHandler() {
			await store.dispatch(
				'alerts/updateJobAlert',
				+route.params.id
			)

			router.push({ name: 'Dashboard' })
		}

		return { onClickHandler, isLoading }
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="Wrapper"] {
	padding-top: 40px;
}
</style>
