import * as yup from 'yup'

// messages
const requiredMessage = 'This field is required'
const requiredMessageUpload = 'This field is required and accepts PDF, RTF, DOCX, DOC, or TXT file.'
const emailMessage = 'Incorrect email address'
const passwordConfirmMessage = 'Password must match Password confirmation'
const checkMessage = 'your check message'
const maxLengthMessage = 'Password must have less than 20 characters'
const minLengthMessage = ({ min }) => (
	`This field must be at least ${min} characters long`
)

// validators
export const defaultField = yup
	.string()
	.required(requiredMessage)
	.min(3, minLengthMessage)

export const email = yup
	.string()
	.required(requiredMessage)
	.email(emailMessage)

export const file = yup
	.mixed()
	.required(requiredMessageUpload)

export const password = yup
	.string()
	.required(requiredMessage)
	.min(8, minLengthMessage)
	.max(20, maxLengthMessage)

export const passwordConfirm = pass => yup
	.string()
	.required(requiredMessage)
	.test(
		'passwords-match',
		passwordConfirmMessage,
		val => val === pass.value
	)

export const check = yup
	.bool()
	.default(false)
	.oneOf(
		[true],
		checkMessage
	)
