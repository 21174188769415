<template>
	<Wrapper v-if="!isLoading">
		<div class="signin-container register-container register-container-new">
		<!-- <Image
			class="left-slot"
			imgName="profile.jpg"
			figureColor="#dab9ff"
		/>
 -->
		<div class="image-container"
			style="background-image:
			url(/wp-content/plugins/th-job-board/assets/img/profile.jpg)"
		>
			<picture>
				<img
					class="left-shot"
					alt ="profile"
					src="/wp-content/plugins/th-job-board/assets/img/profile.jpg"
				/>
			</picture>
		</div>

			<div class="container">
				<Register class="right-slot" />
			</div>
		</div>
	</Wrapper>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Wrapper from '@/components/entry/Wrapper.vue'
// import Image from '@/components/entry/Image.vue'
import Register from '@/components/entry/form/Register.vue'

export default {
	components: { Wrapper, Register },

	setup() {
		const store = useStore()
		const route = useRoute()

		const isLoading = computed(
			() => store.state.isPageLoading
		)

		onMounted(
			async () => {
				if (route.params.id)
					await store.dispatch('postedJobs/getJob', +route.params.id)

				store.commit('setIsPageLoading', false)
			}
		)

		return { isLoading }
	}
}
</script>
