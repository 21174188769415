<template>
	<div data-vue-component-name="SavedJobs" class="savedJobs-dashboard">
		<GreyBlank
			title="Saved jobs"
			:count="jobsCount"
		>
			<div class="jobs-container">
				<JobItem
					v-for="job of jobs"
					:title="job.title"
					:key="job.id"
					@onClickHandler="onClickHandler(job.permalink)"
					@onDeleteHandler="onDeleteHandler(job.id)"
				/>

				<Button v-if="!jobsCount" bordered full>
					No saved jobs
				</Button>

				<Link
					@click="onSearchJobsHandler"
					class="more-jobs"
					:to="{ name: 'PostedJobs' }"
					bold
				>
					Discover new jobs
				</Link>
			</div>

			<Pagination
				:pageCount="pageCount"
				@onPageChange="onPageChange"
			/>
		</GreyBlank>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { usePagination } from '@/hooks'

import Button from '@/components/ui/Button.vue'
import Link from '@/components/ui/Link.vue'
import Pagination from './Pagination.vue'
import GreyBlank from './GreyBlank.vue'
import JobItem from './JobItem.vue'

export default {
	components: {
		Button,
		Link,
		Pagination,
		GreyBlank,
		JobItem
	},

	props: {
		itemsPerPage: {
			type: Number,
			default: 4
		}
	},

	setup(props) {
		const userJobs = computed(
			() => store.state.userJobs.jobs
		)
		const jobsCount = computed(
			() => store.getters['userJobs/jobsCount']
		)

		const store = useStore()
		const [jobs, pageCount, onPageChange] = usePagination(
			userJobs,
			props.itemsPerPage
		)

		function onClickHandler(link) {
			window.location.href = link
		}

		function onDeleteHandler(id) {
			store.dispatch('userJobs/deleteJob', id)
		}

		function onSearchJobsHandler() {
			store.dispatch('filters/clearFiltersAndSearch')
		}

		return {
			jobs,
			jobsCount,
			pageCount,
			onPageChange,
			onClickHandler,
			onDeleteHandler,
			onSearchJobsHandler
		}
	}
}
</script>

<style lang="scss" scoped>
.jobs-container {
	display: flex;
	flex-direction: column;
	height: 100%;

	[data-vue-component-name="Button"] {
		margin-bottom: 30px;
	}
}
</style>
