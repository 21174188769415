<template>
	<div data-vue-component-name="SignIn" class="registerUser">
		<div class="registerUserInnerContainer">
			<h1 class="title">
				Sign in {{ $route.params.id && 'to save your job' }}
			</h1>

			<div>
				<UserSignOut />

				<form @submit.prevent="onSubmit">
					<Input
						v-if="!userEmail"
						placeholder="Email"
						type="email"
						v-model="email.value.value"
						:error="email.errorMessage.value"
					/>

					<div class="password">
						<Link :to="{ name: 'ResetPassword' }">
							Forgot Password?
						</Link>

						<Input
							placeholder="Password"
							type="password"
							v-model="password.value.value"
							:error="password.errorMessage.value"
						/>
					</div>

					<ButtonLoader :loading="loading">
						Sign in
					</ButtonLoader>
				</form>
			</div>

			<RegisterLink />
		</div>
	</div>
</template>

<script>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useForm, useField } from 'vee-validate'
import { useLoader } from '@/hooks'

import * as v from '@/helpers/validators'
import API_ERROR_CODES from '@/constants/apiErrorCodes'

import Link from '@/components/ui/Link.vue'
import Input from '@/components/ui/Input.vue'
import ButtonLoader from '@/components/ui/ButtonLoader.vue'
import RegisterLink from '../RegisterLink.vue'
import UserSignOut from '../UserSignOut.vue'

export default {
	components: {
		Link,
		Input,
		ButtonLoader,
		RegisterLink,
		UserSignOut
	},

	setup() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		const [loading, proceedLoading] = useLoader()

		const { handleSubmit, setFieldValue } = useForm()
		const email = useField('email', v.email)
		const password = useField('password', v.password)

		const userEmail = computed(() => store.getters['user/userEmail'])
		const error = computed(() => store.state.error)

		watch(
			userEmail,
			() => setFieldValue('email', userEmail.value),
			{ immediate: true }
		)

		const onSubmit = handleSubmit(
			async (values, actions) => {
				await proceedLoading(
					() => store.dispatch('user/signIn', values)
				)

				if (error.value) {
					const { code } = error.value.response.data
					const handledCode = API_ERROR_CODES[code]

					return handledCode && actions.setFieldError(
						handledCode.field,
						handledCode.message
					)
				}

				const { id, backTo } = route.params
				const { job } = store.state.postedJobs

				if (id) await store.dispatch('postedJobs/saveJob', +id)
				if (backTo) return router.push({ name: backTo })
				if (job) return window.location.href = job.permalink

				router.push({ name: 'Dashboard' })
			}
		)

		return {
			userEmail,
			email,
			password,
			onSubmit,
			loading
		}
	}
}
</script>

<style lang="scss" scoped>
[data-vue-component-name="SignIn"] {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 70px 36px 40px 40px;

	.title {
		margin: 0 0 26px;
		font-size: 42px;
		font-weight: normal;
		line-height: 1.0714;
		color: $black;

		@include media('<tablet') {
			margin: 0 0 30px;
			font-size: 26px;
		}
	}

	form {
		margin-bottom: 30px;

		[data-vue-component-name="Input"] {
			margin-bottom: 10px;
		}

		.password {
			position: relative;

			[data-vue-component-name="Link"] {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
			}
		}
	}
}
</style>
